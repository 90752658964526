import React, { Fragment, useState, useContext } from "react";
import { MainContext } from "../../../contexts/MainContext.js";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import Flickity from "react-flickity-component";
import { StyledPortfolioPage } from "../PortfolioPage.styled";

const StyledFlickity = styled(Flickity)``;

export default function PortfolioMainFlickity(props) {
  const { portfolioItems, setPortfolioItems } = useContext(MainContext);

  const flickityOptions = {
    initialIndex: 0,
    prevNextButtons: false,
    pageDots: false
  };

  return (
    <Fragment>
      <StyledFlickity
        // flickityRef={(ref1) => (this.flkty = ref1)}
        className="portfolio-main-flickity"
        options={flickityOptions}
        // key="flickity3"
      >
        {portfolioItems}
      </StyledFlickity>
    </Fragment>
  );
}
