import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { Switch } from "@material-ui/core";

import styled from "styled-components";

const StyledSwitch = styled(Switch)`
  position: relative;
  width: 34px;
  height: 18px;
  display: flex;
  padding: 0px;
  margin: ${(props) => props.margin};
  color: #232323;
  z-index: 2000;

  .MuiTouchRipple-root {
    color: transparent;
  }
  .MuiSwitch-switchBase {
    padding: 3px;
    transform: translateX(1px);
    transition: transform 0.15s ease-out;
  }
  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(15px);
  }
  .MuiSwitch-thumb {
    color: #04fbc0;
    width: 12px;
    height: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .MuiSwitch-thumb::after {
    content: url(/svg/brightness_5-24px.svg);
    position: relative;
    color: pink !important;
    height: 14px;
    width: 14px;
  } */
  .Mui-checked .MuiSwitch-thumb {
    color: #232323;
  }
  .MuiSwitch-track {
    background-color: #232323;
    opacity: 1;
    border-radius: 100px;
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: white;
    opacity: 1;
  }
`;

export default function DarkModeSwitch(props) {
  const [checkedDark, setCheckedDark] = useState(false);
  const { theme, themeToggler } = useContext(MainContext);

  const handleChange = (event) => {
    theme === "light" ? setCheckedDark(false) : setCheckedDark(true);
  };

  useEffect(() => {
    handleChange();
  }, [theme]);

  return (
    <div>
      <StyledSwitch
        aria-label="Dark Mode Toggle"
        checked={checkedDark}
        onChange={handleChange}
        onClick={() => {
          themeToggler();
        }}
        name="DarkMode Checked"
        margin={props.margin}
        id="dark-switch"
      />
      <label className="sr-only" for="dark-switch">
        Darkmode toggle switch
      </label>
    </div>
  );
}
