import React, { useState, useContext, useRef } from "react";
import { MainContext } from "../../contexts/MainContext.js";
import { Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import copy from "copy-to-clipboard";

import {
  FaGithub,
  FaCodepen,
  FaLinkedinIn,
  FaMobileAlt,
  FaLayerGroup,
  FaLightbulb,
  FaRegLightbulb,
  FaCode
} from "react-icons/fa";
import { SiCodesandbox } from "react-icons/si";
import { FiDownloadCloud, FiCopy } from "react-icons/fi";
import { IoIosSpeedometer } from "react-icons/io";

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.backgroundLight};
  width: 100%;
  height: 100%;
  position: relative;
  top: 0px;
  overflow-y: scroll;
  transition: ${(props) => props.theme.backgroundTransition};
  .portrait {
    max-width: 250px;
    width: 250px;
    height: 250px;
  }
  .about-content-container {
    margin: ${(props) => (props.isDesktop ? "0 auto 100px" : "0 auto 12px")};
    padding: ${(props) =>
      props.isDesktop ? "0px 120px 24px" : "80px 36px 24px"};
    max-width: 1020px;
  }
  .MuiTypography-h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    padding: 0 0 12px 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
    /* white-space: nowrap; */
  }
  .MuiTypography-h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    padding: 0 0 0 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
    /* white-space: nowrap; */
  }
  .MuiTypography-h3 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
    /* white-space: nowrap; */
  }
  .MuiTypography-subtitle1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
  }
  .MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 0 0 12px 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
  }
  .chips-container {
    margin: 12px 0 12px 0;
  }
  .chip {
    width: fit-content;
    margin: 3px;
    padding: 10px 12px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 36px;
    cursor: default;
  }
  .email-link-container{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-content: center;
    margin: 12px 0 12px 0;
  }
  .email-link {
    color: ${(props) => props.theme.text};
    max-width: 100%;
    background: none;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  .link-icons-container {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin: 0 0 0 0;
  }
  .icon-button {
    background-color: transparent;
    padding: 10px;
    margin: 0 12px;
    border-radius: 100px;
    transition: 0.2s background-color ease-out;
  }
  .icon {
    color: ${(props) => props.theme.text};
    opacity: 1;
    transition: 0.2s opacity ease-out;
  }
  .icon-button:hover .icon{
    opacity: 0.5;
    /* color: ${(props) => props.theme.textInverted}; */
  }
  .icon-copy {
    color: ${(props) => props.theme.text};
    width: fit-content;
    padding: 10px;
    background-color: transparent;
    margin: -5px 6px 0;
    border-radius: 100px;
    transition: 0.2s background-color ease-out;
  }
  .icon-copy:hover {
    background-color: rgba(36, 36, 36, 0.2);
  }
  .icon-large {
    color: ${(props) => props.theme.text};
    width: 40px;
    height: auto;
  }
  .button-icon {
    color: #232323;
    width: 24px;
    height: auto;
  }
  
  .icon-button:hover {
    background-color: rgba(36, 36, 36, 0.2);
  }
  .download-button {
    color: #232323;
    background-color: ${(props) => props.theme.accentLight};
    border-radius: 50px;
    box-shadow: none;
    margin: 12px 0;
    transition: 0.2s background-color ease-out;
  }
  .download-button:hover {
    background-color: ${(props) => props.theme.accentHover};
  }
  strong {
    font-weight: 600;
  }
  .snackbar .MuiPaper-root {
    background-color: ${(props) => props.theme.accentLight};
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  .skills-items-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .skills-row {
    display: flex;
    flex-direction: row;
  }
  
  .skills-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-width: 200px;
    height: 200px;
    /* background-color: ${(props) => props.theme.textInverted}; */
    border-radius: 10px;
    margin: 12px;
  }

  @media screen and (min-width: 768px) {
    .skills-items-container {
    position: absolute;
    left:0;
  }
  .chips-container {
    margin: 212px 0 12px 0;
  }
  }
  @media screen and (max-width: 767px) {
    .skills-items-container {
    min-width: 0px;
  }
    .skills-row {
      flex-direction: column;
      align-items: center;
      margin:0 8px;
    }
    .skills-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 200px;
    height: 180px;
    /* background-color: ${(props) => props.theme.textInverted}; */
    border-radius: 10px;
    margin: 0 12px;
  }
  }
  .skills-item .MuiTypography-subtitle1 {
    text-align: center;
    font-size: 14px;
  }
  .skill-icon {
    width: 40px;
    height: auto;
    color: ${(props) => props.theme.text};
  }
`;

export default function AboutPageComponent(props) {
  const { themeToggler, isDesktop } = useContext(MainContext);
  const textAreaRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [skills, setSkills] = useState([
    "Wordpress",
    "React Js",
    "UX Design",
    "E-Commerce",
    "SVG Animation",
    "HTML",
    "CSS",
    "Javascript",
    "styled-components",
    "Brand Design",
    "Web Optimization",
    "SEO",
    "UI Design",
    "Firebase",
    "Analytics"
  ]);

  const preventDefault = (event) => event.preventDefault();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledBox isDesktop={isDesktop}>
      <Box className="about-content-container">
        <Typography variant="h1" gutterBottom>
          About me.
        </Typography>
        {/* <img
          className="portrait"
          src="https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fprofile-image-placeholder.png?alt=media&token=932765cc-c9fb-4aa3-8eec-1dea5627a2a0"
          alt="Portrait of Joshua Mamo"
        /> */}
        <Typography variant="body1" gutterBottom>
          I'm a Front-End Developer based in Sydney, Australia.
          <br />I love to explore and understand the needs of users, to create
          intuitive and enjoyable user experiences using existing and novel
          solutions.
          <div className="email-link-container">
            <Link
              className="email-link"
              id="email-copy"
              href="mailto: josh@joshmamo.com"
              //onClick={preventDefault}
              ref={textAreaRef}
            >
              Email me: josh@joshmamo.com
            </Link>
            <IconButton
              aria-label="copy email address"
              className="icon-copy"
              onClick={() => {
                copy("josh@joshmamo.com");
                setOpen(true);
              }}
              Text
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiCopy className="icon" />
            </IconButton>
          </div>
          <div className="link-icons-container">
            <IconButton
              aria-label="github"
              className="icon-button"
              href="https://github.com/joshmamo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub className="icon" />
            </IconButton>

            <IconButton
              aria-label="codepen"
              className="icon-button"
              href="https://codepen.io/joshmamo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaCodepen className="icon" />
            </IconButton>

            <IconButton
              aria-label="linkedin"
              className="icon-button"
              href="https://linkedin.com/in/josh-mamo-37380a99"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn className="icon" />
            </IconButton>

            <IconButton
              aria-label="codesandbox"
              className="icon-button"
              href="https://codesandbox.io/u/joshmamo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiCodesandbox className="icon" />
            </IconButton>
          </div>
        </Typography>

        <Typography variant="h2">Skills:</Typography>
        <div className="skills-items-container">
          <div className="skills-row">
            <div className="skills-item">
              <IoIosSpeedometer className="skill-icon" />
              <Typography variant="h3">Fast 1</Typography>
              <Typography variant="subtitle1">
                Fast load times and lag free interaction, my highest priority.
              </Typography>
            </div>
            <div className="skills-item">
              <FaMobileAlt className="skill-icon" />
              <Typography variant="h3">Responsive</Typography>
              <Typography variant="subtitle1">
                Sites that look and work perfectly on all displays and devices.
              </Typography>
            </div>
          </div>
          <div className="skills-row">
            <div className="skills-item">
              <FaLayerGroup className="skill-icon" />
              <Typography variant="h3">Intuitive</Typography>
              <Typography variant="subtitle1">
                Understanding client & user needs to design user experiences.
              </Typography>
            </div>
            <div className="skills-item">
              <FaRegLightbulb className="skill-icon" />
              <Typography variant="h3">Custom</Typography>
              <Typography variant="subtitle1">
                Innovative solutions, profitably balancing beauty and usability.
              </Typography>
            </div>
          </div>
        </div>

        <Box className="chips-container">
          {skills.map((item, i) => (
            <Chip
              key={i}
              className="chip"
              label={item}
              onClick={() => {
                console.log(item);
              }}
            />
          ))}
        </Box>
        {/* <Typography variant="h2">Links:</Typography> */}

        <Typography variant="h2">Experience:</Typography>
        <Typography variant="body1">
          <strong>Freelance Website Development | 2019 - 2020 </strong> <br />-
          Client work, mostly designing and building custom Wordpress sites.
          <br />- Website design and development.
          <br />
          <br />
          <strong>Bloom Campervans | 2019 </strong> <br />- An new venture,
          offering an affordable, fully self-contained, 5 minute campervan
          conversion product.
          <br />- Founder and Product Designer.
          <br />
          <br />
          <strong>Cultivate3D Pty Ltd | 2011 - 2018 </strong> <br />- 3D Printer
          startup, developing, manufacturing and selling several novel 3D
          printers of my own design.
          <br />- Founder, Lead Product Designer and Operations Manager.
        </Typography>
        <div className="link-icons-container">
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="download-button"
            startIcon={<FiDownloadCloud className="button-icon" />}
            href="https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/Joshua%20Mamo%20-%20Resume%20-%20October%202020.pdf?alt=media&token=daa3469b-cebd-4ed6-ae61-c82f54107ffd"
            download
          >
            Download PDF Resume
          </Button>
        </div>
      </Box>
    </StyledBox>
  );
}
