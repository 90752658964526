import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import InfoDrawer from "./InfoDrawer.js";
import { Typography } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import SettingsIcon from "@material-ui/icons/Settings";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Box from "@material-ui/core/Box";
import { Switch } from "@material-ui/core";

import "../styles.css";
import "../overrides.css";
import styled from "styled-components";
import DarkModeSwitch from "../buttons/DarkModeSwitch.js";

const StyledBox = styled(Box)`
  padding: 0px 36px;

  .MuiTypography-h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    padding: 0 0 12px 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
    /* white-space: nowrap; */
  }
  .MuiTypography-subtitle1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 0 0 12px 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
  }
`;

const StyledControlsBox = styled(Box)`
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: auto;
  right: 24px;
  bottom: 0px;
  z-index: 1350;
  border-radius: 4px 4px 0 0;
  transition: 0.2s background-color ease-out;
  background-color: ${(props) => props.theme.backgroundControlPanels};
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
`;

const StyledSwitch = styled(Switch)`
  position: relative;
  width: 34px;
  height: 18px;
  display: flex;
  padding: 0px;
  margin: 12px;
  color: #232323;
  z-index: 2000;

  .MuiTouchRipple-root {
    color: transparent;
  }
  .MuiSwitch-switchBase {
    padding: 3px;
    transform: translateX(1px);
    transition: transform 0.15s ease-out;
  }
  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(15px);
  }
  .MuiSwitch-thumb {
    color: white;
    width: 12px;
    height: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .MuiSwitch-thumb::after {
    content: url(/svg/brightness_5-24px.svg);
    position: relative;
    color: pink !important;
    height: 14px;
    width: 14px;
  } */
  .Mui-checked .MuiSwitch-thumb {
    color: #232323;
  }
  .MuiSwitch-track {
    background-color: #232323;
    opacity: 1;
    border-radius: 100px;
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: white;
    opacity: 1;
  }
`;

const StyledToggleSwitch = styled(Box)`
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 48px;
    height: 24px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }
  label:after {
    content: url(/svg/brightness_5-24px.svg);
    position: absolute;
    top: 3px;
    left: 5px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
  input:checked + label {
    background: #bada55;
  }
  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
  label:active:after {
    width: 22px;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.buttonBG};
  font-size: 48px;
`;

const StyledIconButtonInfo = styled(IconButton)`
  color: ${(props) => props.theme.buttonBG};
`;

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-root {
    z-index: 3000;
  }
  z-index: 3000;
  .MuiDrawer-paper {
    position: absolute;
    width: ${(props) => (props.isDesktop ? "50vw" : "100vw")};
    border-radius: 40px 0 0 40px;
    background-color: ${(props) => props.theme.backgroundGrey};
    padding: 48px 24px;
    color: ${(props) => props.theme.text};
    transition: 0.2s background-color ease-out, 0.2s color ease-out;
  }
`;

export default function Controls(props) {
  const [counter, setCounter] = useState(0);
  const [checkedDark, setCheckedDark] = useState(false);
  const {
    themeToggler,
    isDesktop,
    setTheme,
    drawerOpen,
    setDrawerOpen,
    toggleDrawer
  } = useContext(MainContext);

  const handleChange = (event) => {
    setCheckedDark(!checkedDark);
  };

  return (
    <StyledBox>
      <StyledControlsBox>
        {/* Dark Mode Switch */}
        {/* <StyledToggleSwitch>
          <input
            type="checkbox"
            id="switch"
            checked={checkedDark}
            onChange={handleChange}
            onClick={() => {
              themeToggler();
            }}
          />
          <label for="switch">Toggle</label>
        </StyledToggleSwitch> */}
        <DarkModeSwitch margin="12px" />
        <StyledIconButtonInfo
          className={`${"info"} ${"bounce"} ${"controls"}`}
          onClick={() => {
            toggleDrawer();
          }}
          aria-label="info"
        >
          <InfoIcon />
        </StyledIconButtonInfo>
        <StyledIconButton
          className="controls"
          onClick={() => {
            setCounter(counter + 1);
            //window.location.reload()
          }}
          aria-label="replay"
        >
          <SettingsIcon />
        </StyledIconButton>
        {/* <StyledIconButton
          className="controls"
          onClick={() => {
            setCounter(counter + 1);
            //window.location.reload()
          }}
          aria-label="replay"
        >
          <ReplayIcon />
        </StyledIconButton> */}
      </StyledControlsBox>
      <InfoDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
    </StyledBox>
  );
}
