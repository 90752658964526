import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import PortfolioCardDesktop from "../portfolioDesktop/PortfolioCardDesktop.js";
import PortfolioCardMobile from "../portfolioMobile/PortfolioCardMobile.js";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export default function PortfolioItemBrandedGW(props) {
  const portfolioItem = {
    cardTitle: "Engraved.com.au",
    subtitle: "Lorem Ipsum",
    imgDesktop:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fengraved.jpg?alt=media&token=8ac3a231-4ea3-4886-9951-a515612cd3c6",
    imgMobile:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fengraved-mobile.jpg?alt=media&token=36310760-b74f-48b7-b4ff-51990897efed",
    imgMobile2: "",
    imgMobile3: "",
    tab1Title: "Brief",
    tab1Body: (
      <p>
        A long establised commercial laser cutting business, this project that
        began at a moments notice when the existing, aged website was corrupted.
        I quickly built a temporary site with the existing sitemap referencing
        wayback machine. This to buy some time to properly design a new site
        which was then applied to the new site.
      </p>
    ),
    tab2Title: "Design",
    tab2Body: (
      <p>
        Working with the client to understand the target users we designed an
        aesthetic and experience that had a modern and bold feel that fit well
        with the business' services. The use of rectenglar shapes and strong
        lines is representative of the laser engraving and labels the business
        produce.
      </p>
    ),
    tab3Title: "Development",
    tab3Body: (
      <p>
        With a tight budget and timeframe and fairly basic needs, the client and
        I decided Wordpress and a modified Elementor theme would be an
        appropriate solution. <br /> Custom features include:
        <ul>
          <li>Hide pricelist download unless user registered and approved</li>
          <li>User approval functionality</li>
          <li>Detailed quote request form</li>
        </ul>
      </p>
    ),
    tab4Title: "Performance",
    tab4Body:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt mollit anim id est laborum.",
    displayImageOnMobile: "desktop",
    alt: "alt text",
    url: "https://engraved.com.au/"
  };

  return (
    <Fragment>
      <Desktop>
        <PortfolioCardDesktop
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          tab2Title={portfolioItem.tab2Title}
          tab2Body={portfolioItem.tab2Body}
          tab3Title={portfolioItem.tab3Title}
          tab3Body={portfolioItem.tab3Body}
          tab4Title={portfolioItem.tab4Title}
          tab4Body={portfolioItem.tab4Body}
          url={portfolioItem.url}
          alt={portfolioItem.alt}
        />
      </Desktop>
      <Mobile>
        <PortfolioCardMobile
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          tab2Title={portfolioItem.tab2Title}
          tab2Body={portfolioItem.tab2Body}
          tab3Title={portfolioItem.tab3Title}
          tab3Body={portfolioItem.tab3Body}
          tab4Title={portfolioItem.tab4Title}
          tab4Body={portfolioItem.tab4Body}
          displayImageOnMobile={portfolioItem.displayImageOnMobile}
          alt={portfolioItem.alt}
          index={props.index}
          activeIndex={props.activeIndex}
          url={portfolioItem.url}
          open={props.open}
        />
      </Mobile>
    </Fragment>
  );
}
