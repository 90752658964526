import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import PortfolioCardDesktop from "../portfolioDesktop/PortfolioCardDesktop.js";
import PortfolioCardMobile from "../portfolioMobile/PortfolioCardMobile.js";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export default function PortfolioItemCafePennoz(props) {
  const portfolioItem = {
    cardTitle: "Cafe Pennoz",
    subtitle: "Lorem Ipsum",
    imgDesktop:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fcafepennoz.jpg?alt=media&token=ab7c22ef-4c5d-4e6b-a186-b6059cc25805",
    imgMobile:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fcafepennoz-mobile.jpg?alt=media&token=1e210919-518e-4779-960f-51b57508b6f5",

    imgMobile2: "",
    imgMobile3: "",
    tab1Title: "Overview",
    tab1Body: (
      <p>
        A well established and well loved hospitality business on the Central
        Coast.
        <br />
        With a very simple and specific purpose this site was designed and built
        to give customers the information they need as quickly as possible;
        location, trading hour, menus and prices.
      </p>
    ),
    tab2Title: "Design",
    tab2Body: (
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt
        mollit anim id est laborum."
      </p>
    ),
    tab3Title: "Development",
    tab3Body: (
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt
        mollit anim id est laborum."
      </p>
    ),
    tab4Title: "Performance",
    tab4Body: (
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt
        mollit anim id est laborum."
      </p>
    ),
    displayImageOnMobile: "mobile or desktop",
    alt: "alt text",
    url: "http://cafepennoz.com.au/"
  };

  return (
    <Fragment>
      <Desktop>
        <PortfolioCardDesktop
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          // tab2Title={portfolioItem.tab2Title}
          // tab2Body={portfolioItem.tab2Body}
          url={portfolioItem.url}
          alt={portfolioItem.alt}
        />
      </Desktop>
      <Mobile>
        <PortfolioCardMobile
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          // tab2Title={portfolioItem.tab2Title}
          // tab2Body={portfolioItem.tab2Body}
          displayImageOnMobile={portfolioItem.displayImageOnMobile}
          alt={portfolioItem.alt}
          index={props.index}
          activeIndex={props.activeIndex}
          url={portfolioItem.url}
          open={props.open}
        />
      </Mobile>
    </Fragment>
  );
}
