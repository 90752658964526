import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useLocation
} from "react-router-dom";
import { Typography, Box, Button } from "@material-ui/core";
import { StyledMenu } from "./Menu.styled";

const Menu = ({ open, setOpen }) => {
  let location = useLocation();

  // useEffect(() => {
  //   //undates the position on fthe indicator dependent on the pathname
  //   switch (location.pathname) {
  //     case "/":
  //       console.log("Home");
  //       ulSlideIndicator(ref1);
  //       break;
  //     case "/whatido":
  //       console.log("whatido");
  //       ulSlideIndicator(ref2);
  //       break;
  //     case "/portfolio":
  //       console.log("portfolio");
  //       ulSlideIndicator(ref3);
  //       break;
  //     case "/contact":
  //       console.log("contact");
  //       ulSlideIndicator(ref4);
  //       break;
  //     default:
  //       console.log(`Sorry, I don't know where you want to go.`);
  //   }
  // }, [location]);

  return (
    <StyledMenu open={open}>
      <Link
        className={`link ${location.pathname === "/" ? "active-mobile" : null}`}
        to="/"
        onClick={() => setOpen(!open)}
      >
        Who
      </Link>
      <Link
        className={`link ${
          location.pathname === "/about" ? "active-mobile" : null
        }`}
        to="/about"
        onClick={() => setOpen(!open)}
      >
        About
      </Link>
      <Link
        className={`link ${
          location.pathname === "/portfolio" ? "active-mobile" : null
        }`}
        to="/portfolio"
        onClick={() => setOpen(!open)}
      >
        Portfolio
      </Link>
      <Link
        className={`link ${
          location.pathname === "/contact" ? "active-mobile" : null
        }`}
        to="/contact"
        onClick={() => setOpen(!open)}
      >
        Contact
      </Link>
    </StyledMenu>
  );
};
export default Menu;
