import styled, { keyframes } from "styled-components";

const slideInLeft = keyframes`
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;
const slideOutLeft = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
`;

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutRight = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
`;

// Styles

const Page = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center; 
  h2 {
    font-family: "M PLUS Rounded 1c", sans-serif;
    color: #2c2c28;
  }
  p {
    font-size: 1rem;
    max-width: 400px;
    margin: 20px auto;
    color: #2c2c28;
  }
  a {
    text-decoration: none;
    color: #fff;
    padding: 10px 20px;
    border: 1px solid #4776e6;
    border-radius: 5px;
    background: #2c2c28;
    border: none;
    display: block;
    max-width: 60%;
    margin: 0 auto 18px auto;
  }

  img {
  }


    /* grid-template-areas: "empty-top" "portfolio-main-display-area" "portfolio-thumb-selector-area"; */
    .portfolio-page-container{
      display: flex;
      flex-direction: column;
      background-color: ${(props) => props.theme.backgroundLight};
      transition: ${(props) => props.theme.backgroundTransition};

    }

  .empty-top {
    grid-area: empty-top;
    height: 80px;
    background-color: ${(props) => props.theme.backgroundLight};
  }

  /* Main Slides Styles*/

  .portfolio-main-flickity {
    z-index: 10;
    overflow-y: hidden; // changed from scroll - Josh
    overflow-x: hidden; 
    height: 80vh;
}
  }

  .portfolio-item-container {
    margin-top: 0;
    /* margin-right: 55vw; */
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .portfolio-slide {
    width: 60vw;
    min-width: 450px;
    max-width: 800px;
    height: 36vw;
    min-height: 160px;
    max-height: 256px;
    object-fit: cover;
    object-position: 0px 0%;
    border-radius: 12px;
    border: 4px solid #232323;
    -webkit-transition: object-position 3s ease-in-out;
    -moz-transition: object-position 3s ease-in-out;
    -ms-transition: object-position 3s ease-in-out;
    -o-transition: object-position 3s ease-in-out;
    transition: object-position 3s ease-in-out;
  }

  /* Thumbnails Styles*/

 #thumbnails-bottom-bar{
  position: absolute;
  width: 100%;
  bottom: 90px;
  transform: translateY(0%); /* Change to 50% or more to make bottom nav bar move down to make more room for main content  */
  transition: 0.2s all ease-out;
  z-index: 1250;
  @media screen and (max-height: 1000px) {
    transform: translateY(50%); /* Change to 50% or more to make bottom nav bar move down to make more room for main content  */
    transition: 0.2s all ease-out;
  }
  }

  #thumbnails-bottom-bar:hover {
    transform: translateY(0px);
  }
 

  #bottom-grey-bar {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 220px;
    border-radius: 40px 40px 0 0;
    background: ${({ theme }) => theme.backgroundGrey};
    z-index: 1;
  }

  .portfolio-thumb-selector-flickity {
    position: relative;
    z-index: 2;
    height: 100%;
    
  }

  .portfolio-thumbnail-container {
    position: relative;
    margin-top: 50px;
    margin-right: 40px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .portfolio-thumbnail-shadow {
    height: 15px;
    width: 100%;
    margin-top: 10px;
    background: ${({ theme }) => theme.shadow};
    border-radius: 100%;
    transition: all 0.3s ease-out;
  }

  .is-selected .portfolio-thumbnail-shadow {
    height: 15px;
    width: 100%;
    margin-top: 25px;
    background: ${({ theme }) => theme.shadowSelected};
    border-radius: 100%;
  }

  .portfolio-thumbnail {
    width: 30vw;
    min-width: 250px;
    max-width: 400px;
    height: 18vw;
    min-height: 160px;
    max-height: 256px;
    object-fit: cover;
    object-position: 0px 0%;
    background-color: #e0e0e0;
    border-radius: 12px;
    border: 4px solid #232323;
    -webkit-transition: object-position 3s ease-in-out;
    -moz-transition: object-position 3s ease-in-out;
    -ms-transition: object-position 3s ease-in-out;
    -o-transition: object-position 3s ease-in-out;
    transition: object-position 3s ease-in-out;
  }
  @media screen and (min-width: 890px) {
    .portfolio-thumbnail {
    width: 30vw;
    min-width: 250px;
    max-width: 275px;
    height: 18vw;
    min-height: 160px;
    max-height: 170px;
    object-fit: cover;
    object-position: 0px 0%;
    background-color: #e0e0e0;
    border-radius: 12px;
    border: 4px solid #232323;
    -webkit-transition: object-position 3s ease-in-out;
    -moz-transition: object-position 3s ease-in-out;
    -ms-transition: object-position 3s ease-in-out;
    -o-transition: object-position 3s ease-in-out;
    transition: object-position 3s ease-in-out;
  }
  }

  .portfolio-thumbnail:hover {
    object-position: 0px 100%;
  }
`;

export const StyledPortfolioPage = styled(Page)`
  background-color: ${(props) => props.theme.backgroundLight};
  &.page-enter {
    animation: ${slideInRight} 0.2s forwards;
  }
  &.page-exit {
    animation: ${slideOutRight} 0.2s forwards;
  }
`;
