import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext
} from "react";
import { useLocation } from "react-router-dom";
import { MainContext } from "../contexts/MainContext.js";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";

import { GlobalStyles } from "../global";
import { theme } from "../theme";

const SideNavContainer = styled(Box)`
  position: absolute;
  display: flex;
  height: auto;
  align-items: center;
  position: fixed;
  margin: 0;
  top: 40vh;
  right: 0px;
  z-index: 1200;
  .sidenav-bg {
    width: 120px;
    border-radius: 10px 0 0 10px;
    background-color: ${(props) => props.theme.backgroundLight};
    transition: ${(props) => props.theme.backgroundTransition};
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }

  ul {
    position: relative;
    padding-left: 0;
    text-align: right;
    list-style: none;
    list-style-type: none;
    z-index: 1300;
  }

  .list-item-indicator {
    opacity: 1;
    content: "";
    display: block;
    position: absolute;
    top: ${(props) => props.indicatorTop};
    right: 2px; //was -1px
    width: 4px;
    height: 18px;
    background: ${(props) => props.theme.accentLight};
    z-index: 1300;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }

  .list-item.active-list-item {
    color: ${(props) => props.theme.text};
  }
  /* .link .active-list-item {
    pointer-events: none;
  } */

  ul:hover .list-item-indicator {
    opacity: 1;
  }

  ul li {
  }

  .list-item {
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    max-width: 120px;
    font-family: ${(props) => props.theme.fontPrimary};
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    text-decoration: none;
    text-transform: uppercase;
    color: ${(props) => props.theme.text};
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 1500;
  }
`;

export default function SideNav(props) {
  const { isDesktop } = useContext(MainContext);
  let location = useLocation();
  const [indicatorTop, setIndicatorTop] = useState("12px");
  const refIndicator = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();

  useEffect(() => {
    updateIndicatorLocation();
  });

  useEffect(() => {
    updateIndicatorLocation();
  }, [location]);

  //Updates the position on fthe indicator dependent on the pathname
  const updateIndicatorLocation = () => {
    switch (location.pathname) {
      case "/":
        console.log("Home");
        ulSlideIndicator(ref1);
        break;
      // case "/whatido":
      //   console.log("whatido");
      //   ulSlideIndicator(ref2);
      //   break;
      case "/about":
        console.log("about");
        ulSlideIndicator(ref2);
        break;
      case "/portfolio":
        console.log("portfolio");
        ulSlideIndicator(ref3);
        break;
      case "/contact":
        console.log("contact");
        ulSlideIndicator(ref4);
        break;
      default:
        console.log(`Sorry, I don't know where you want to go.`);
    }
  };

  // UL Hover Indicator Function
  // ============================
  function ulSlideIndicator(listItem) {
    //console.log(listItem.current.parentElement.parentElement);
    if (isDesktop) {
      var list = listItem.current.parentElement.parentElement,
        indicatorHeight = listItem.current.offsetHeight,
        newPosition = listItem.current.offsetTop;

      setIndicatorTop(`${newPosition}px`);
    } else {
      //put mobile indicator code here
    }
    // 'height'	: indicatorHeight
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Fragment>
        {/* Toggle between Desktop/Tablet Nav and Mobile Nav */}
        {isDesktop ? (
          <SideNavContainer
            className="sidenav-container"
            indicatorTop={indicatorTop}
          >
            <div className="sidenav-bg">
              <ul className="list">
                <span className="list-item-indicator" ref={refIndicator} />

                {/* --------- SideNav Item #1 -----------*/}
                <Link ref={ref1} className="link" to="/">
                  <li
                    className={`list-item ${
                      location.pathname === "/" ? "active-list-item" : null
                    }`}
                  >
                    Who
                  </li>
                </Link>

                {/* --------- SideNav Item #2 -----------*/}
                {/* <Link ref={ref2} className="link" to="/whatido">
                  <li
                    className={`list-item ${
                      location.pathname === "/whatido"
                        ? "active-list-item"
                        : null
                    }`}
                    onClick={() => ulSlideIndicator(ref2)}
                  >
                    What I Do
                  </li>
                </Link> */}
                {/* --------- SideNav Item #2 -----------*/}
                <Link ref={ref2} className="link" to="/about">
                  <li
                    className={`list-item ${
                      location.pathname === "/about" ? "active-list-item" : null
                    }`}
                    onClick={() => ulSlideIndicator(ref2)}
                  >
                    About
                  </li>
                </Link>
                {/* --------- SideNav Item #3 -----------*/}
                <Link ref={ref3} className="link" to="/portfolio">
                  <li
                    className={`list-item ${
                      location.pathname === "/portfolio"
                        ? "active-list-item"
                        : null
                    }`}
                    onClick={() => ulSlideIndicator(ref3)}
                  >
                    Porfolio
                  </li>
                </Link>

                {/* --------- SideNav Item #4 -----------*/}
                <Link ref={ref4} className="link" to="/contact">
                  <li
                    className={`list-item ${
                      location.pathname === "/contact"
                        ? "active-list-item"
                        : null
                    }`}
                    onClick={() => ulSlideIndicator(ref4)}
                  >
                    Contact
                  </li>
                </Link>
              </ul>
            </div>
          </SideNavContainer>
        ) : null}
      </Fragment>
    </ThemeProvider>
  );
}
