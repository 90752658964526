import React, { Fragment, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import CTAButton from "../../buttons/CTAButton";
import Box from "@material-ui/core/Box";
import SVG from "react-inlinesvg";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import Flickity from "react-flickity-component";
import WhatIDoCardDesktop from "./WhatIDoCardDesktop";

import "../../flickity.css";

/*This is used to make the "What I Do" desktop 
page scroll properly, it still is not perfect... */
const StyledMainBox = styled(Box)`
  position: absolute;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundLight};
  .carousel-container {
    z-index: 2001;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: auto;
  margin: 10% 0;
  .carousel-container {
    margin: 36px 120px 0 0;
    height: auto;
    overflow: hidden;
  }
`;

const TextContainerBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 450px;
  z-index: 100;
  /* Type Styles */
  .MuiTypography-h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: ${(props) => props.theme.text};
    text-align: left;
    margin-bottom: 18px;
    /* white-space: nowrap; */
  }
  .MuiTypography-subtitle1 {
    font-family: "Open-Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: ${(props) => props.theme.text};
    text-align: left;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 767px) {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 85px;
    left: 40px;
    height: auto;
    max-width: calc(100vw - 70px);
    z-index: 100;
    .MuiTypography-h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin-bottom: 8px;
      /* white-space: nowrap; */
    }
    .MuiTypography-subtitle1 {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin-bottom: 16px;
    }
  }
`;

const Carousel = () => {
  let flkty;
  let [carouselIndex, setCarouselIndex] = useState(null);
  const selectSlide = (index) => {
    if (flkty) {
      console.log("log" + flkty);
      flkty.select(index);
      console.log(flkty.selectedIndex);
      // console.log(index);
    }
  };

  useEffect(() => {
    if (flkty) {
      console.log(carouselIndex);
      console.log(flkty);
      flkty.select(carouselIndex);
    }
  }, [carouselIndex]);

  return (
    <Flickity
      flickityRef={(c) => (flkty = c)}
      className="services-anim-1"
      options={flickityOptions}
    >
      <WhatIDoCardDesktop
        svgSrc="/svg/graphs.svg"
        cardTitle="SEO & SEM"
        subtitle="We have packages suitable for all projects and budgets"
        body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt mollit anim id est laborum."
        scale="150px"
        translateHor="-40px"
        translateVert="-15px"
        marginTopH2="0"
        onClick={() => {
          setCarouselIndex(0);
        }}
        // selectedIndex={}
      />

      <WhatIDoCardDesktop
        svgSrc="/svg/design-card.svg"
        cardTitle="Design"
        subtitle="We have packages suitable for all projects and budgets"
        body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt mollit anim id est laborum."
        scale="150px"
        translateHor="0px"
        translateVert="0px"
        marginTopH2="0"
        onClick={() => {
          selectSlide(1);
        }}
      />
      <WhatIDoCardDesktop
        svgSrc="/svg/user-flow.svg"
        cardTitle="UX Design & Optimization"
        subtitle="We have packages suitable for all projects and budgets"
        scale="150px"
        translateHor="0px"
        translateVert="0px"
        marginTopH2="0"
        onClick={() => {
          selectSlide(2);
        }}
      />
      <WhatIDoCardDesktop
        svgSrc="/svg/coding.svg"
        cardTitle="Development"
        subtitle="We have packages suitable for all projects and budgets"
        scale="150px"
        translateHor="0px"
        translateVert="0px"
        marginTopH2="0"
        onClick={() => {
          selectSlide(3);
        }}
      />
      <WhatIDoCardDesktop
        svgSrc="/svg/animation.svg"
        cardTitle="Animation"
        subtitle="We have packages suitable for all projects and budgets"
        scale="150px"
        translateHor="0px"
        translateVert="0px"
        marginTopH2="0"
        onClick={() => {
          selectSlide(4);
        }}
      />
      <WhatIDoCardDesktop
        svgSrc="/svg/ecommerce.svg"
        cardTitle="Ecommerce"
        subtitle="We have packages suitable for all projects and budgets"
        scale="150px"
        translateHor="0px"
        translateVert="0px"
        marginTopH2="0"
        selectSlide={() => {
          selectSlide(5);
        }}
        onClick={() => {
          selectSlide(5);
        }}
      />
    </Flickity>
  );
};

const flickityOptions = {
  initialIndex: 1
};

export default function WhatIDoDesktop(props) {
  let history = useHistory();

  return (
    <StyledMainBox>
      <StyledBox className="desktop-main">
        <TextContainerBox>
          <Typography variant="h1" gutterBottom>
            What I do
            {/* {props.cardTitle} */}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Some text about all the awesome stuff I can do.
            {/* {props.subtitle} */}
          </Typography>
          <CTAButton text="My Work" linkTo="/portfolio" />
        </TextContainerBox>
        <div className="carousel-container">
          <Carousel />
        </div>
      </StyledBox>
    </StyledMainBox>
  );
}
