import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import styled from "styled-components";
import { Box } from "@material-ui/core";

const StyledCheckbox = styled(Box)`
  background-color: ${(props) =>
    !props.checked ? props.theme.backgroundGrey : "#04fbc0"};
  border-radius: 4px;
  border: ${(props) =>
    !props.checked
      ? `2px solid ${props.theme.backgroundGrey}`
      : "2px solid #04fbc0"};
  margin: 6px;
  transition: 0.1s background-color ease-out, 0.1s border ease-out;
  :hover {
    /* background-color: ${(props) =>
      !props.checked ? "#f2f2f2" : "#04fbc0"}; */
    border: ${(props) =>
      !props.checked ? "2px solid #04fbc0" : "2px solid #04fbc0"};
      transition: 0.15s border ease-out;
  }
  .MuiFormControlLabel-root {
    padding: 0px 12px 0 0;
  }
  .MuiSvgIcon-root {
    /* display: none; */
    color: ${(props) => (!props.checked ? "#b2b2b2" : "#303030")};
    width: auto;
    height: 26px;
    transition: 0.1s color ease-out;
  }
  .MuiFormControlLabel-root {
    margin: 0;
  }
  .MuiButtonBase-root.MuiIconButton-root {
    /* display: none; */
  }
  .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: ${(props) => (!props.checked ? props.theme.text : "#232323")};
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

export default function CheckboxButton(props) {
  return (
    <StyledCheckbox
      className="checkbox-button"
      checked={props.checked}
      fontSize="small"
    >
      <FormControlLabel
        control={
          <Checkbox
            icon={
              <RadioButtonUncheckedIcon
                classname="check-icon"
                fontSize="small"
              />
            }
            checkedIcon={<CheckCircleIcon classname="check-icon" />}
            checked={props.checked}
            onChange={props.onChange}
            name={props.name}
            color="primary"
          />
        }
        label={props.label}
      />
    </StyledCheckbox>
  );
}
