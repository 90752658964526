import React, { Fragment, useContext, useState } from "react";
import { MainContext } from "../../../contexts/MainContext.js";
import { useMediaQuery } from "react-responsive";
import CTAButton from "../../../buttons/CTAButton";
import Box from "@material-ui/core/Box";
import SVG from "react-inlinesvg";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

import PortfolioCardMobile from "./PortfolioCardMobile";

const StyledMainBox = styled(Box)`
  position: absolute;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.backgroundTransition};

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const TextContainerBox = styled(Box)`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  left: 20%;
  max-width: 450px;
  z-index: 100;
  /* Type Styles */
  @media screen and (max-width: 767px) {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 85px;
    left: 48px;
    height: auto;
    max-width: calc(100vw - 70px);
    z-index: 100;
    .MuiTypography-h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin-bottom: 8px;
      /* white-space: nowrap; */
    }
    .h2-mobile {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 36px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin: 12px 0 12px; /* white-space: nowrap; */
    }
    .MuiTypography-subtitle1 {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin-bottom: 16px;
    }
    .cta-button {
      margin: 6px 0;
    }
  }
  @media screen and (max-width: 480px) {
    left: calc(24px + 2.5vw);
  }
`;

const TextContainerBox1 = styled(Box)`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  left: 20%;
  max-width: 450px;
  z-index: 100;
  /* Type Styles */
  @media screen and (max-width: 767px) {
    position: relative;
    display: flex;
    flex-direction: column;
    left: 48px;
    height: auto;
    max-width: calc(100vw - 70px);
    z-index: 100;
    .h2-mobile {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 36px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin: 12px 0 12px; /* white-space: nowrap; */
    }
  }
  @media screen and (max-width: 480px) {
    left: calc(24px + 2.5vw);
  }
`;

const StyledCardsContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default function PortfolioPageMobile() {
  const { portfolioItems, setPortfolioItems } = useContext(MainContext);
  const [activePortfolioCard, setActivePortfolioCard] = useState(null);

  return (
    <StyledMainBox>
      <TextContainerBox>
        <Typography className="h1-mobile" variant="h1" gutterBottom>
          My Work
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Some text about all my awesome projects.
        </Typography>
        <CTAButton
          // href="/portfolio"
          className="cta-button-portfolio"
          text="Get in Touch"
          linkTo="/contact"
        />
        <Typography className="h2-mobile" variant="h2" gutterBottom>
          Projects
        </Typography>
      </TextContainerBox>

      <StyledCardsContainer>{portfolioItems}</StyledCardsContainer>
      <TextContainerBox1>
        <Typography className="h2-mobile" variant="h2" gutterBottom>
          Tech Demos
        </Typography>
      </TextContainerBox1>
    </StyledMainBox>
  );
}
