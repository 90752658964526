import React, { Fragment, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import ExpandButton from "../../buttons/ExpandButton";
import { Typography } from "@material-ui/core";

const CardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 18px auto 0;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.cardBG};
  border-radius: 0 40px 0 40px;
  /* Styles to lower the opacity of cards that are not currently selected */
  &.isSelectedCard {
    height: auto;
  }
  &.MuiBox-root {
    opacity: 1;
    transition: all 0.3s ease;
  }
  &.MuiBox-root.is-selected {
    opacity: 1;
  }
  .type-svg-flex {
    display: flex;
    flex-direction: row;
  }
  /* Type Styles */
  .MuiTypography-h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: ${(props) => props.theme.text};
    text-align: left;
    /* white-space: nowrap; */
  }
  .MuiTypography-subtitle1 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
  }
`;

const StyledSVG = styled(SVG)`
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: ${(props) => (props.scale ? props.scale : "auto")};
  height: ${(props) => (props.scale ? props.scale : "auto")};
  transform: ${(props) =>
    props.translateX && props.translateY
      ? `translate(${props.translateX}, ${props.translateY})`
      : "translate(0,0)"};
`;

const BodyBox = styled(Box)`
  /* scrollbar styles */
  /* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
  overflow-y: scroll;
  max-height: ${(props) =>
    props.activeIndex === props.index ? "500px" : "0px"}; 
  /* transform-origin: 100% 0%;
  transform: ${(props) =>
    props.activeIndex === props.index ? "scaleY(1)" : "scaleY(0)"}; */
  transition:  0.8s max-height cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 90%;
  margin: 0 auto;
    .MuiTypography-body1 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
    /* opacity: ${(props) => (props.activeIndex === props.index ? "1" : "0")}; ;
    transition: 0.5s opacity cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
    /* opacity: ${(props) => (props.activeIndex === props.index ? 1 : 0)}; */
  }
`;

export default function WhatIDoCard(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.activeIndex === props.index ? setOpen(true) : setOpen(false);
  }, [props.activeIndex, props.index]);

  return (
    <Fragment>
      <CardBox
        className="card-bg"
        marginTopH2={props.marginTopH2}
        onClick={props.onClick}
        activeIndex={props.activeIndex}
        index={props.index}
      >
        <Box className="type-svg-flex">
          <div>
            <Typography variant="h2" gutterBottom>
              {props.cardTitle}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {props.subtitle}
            </Typography>
          </div>
          <StyledSVG
            translateX={props.translateHor}
            translateY={props.translateVert}
            src={props.svgSrc}
            scale={props.scale}
          />
        </Box>
        <ExpandButton
          padding="6px"
          open={open}
          size="large"
          onClick={() => setOpen(!open)}
        />
        <BodyBox activeIndex={props.activeIndex} index={props.index}>
          <Typography variant="body1" gutterBottom>
            {props.body}
          </Typography>
        </BodyBox>
      </CardBox>
    </Fragment>
  );
}
