import React from "react";

import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styled from "styled-components";

const StyledIconButton = styled(IconButton)`
  box-shadow: none;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  background-color: white;
  color: #232323;
  align-self: flex-start;
  transition: 0.2s background-colour ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background-color: #04fbc0;
  }
  @media screen and (max-width: 767px) {
  }
`;

export default function BackButton(props) {
  return (
    <StyledIconButton
      aria-label="back to all"
      className="back-button"
      margin={props.margin}
      padding={props.padding}
      onClick={props.onClick}
    >
      <ArrowBackIcon fontSize="large" />
    </StyledIconButton>
  );
}
