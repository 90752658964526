import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import { Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledBox = styled(Box)`
  width: auto;
  height: auto;
  .anchor-cont {
    text-decoration: none;
    background: none;
  }
`;

const StyledButton = styled(Button)`
  background: #04fbc0;
  border-radius: 2px;
  box-shadow: none;
  padding: 12px 24px;
  margin: 36px 48px 0 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "16px")}
  line-height: 21px;
  color: #232323;
  align-self: flex-start;
  transform: scale(1);
  transition: 0.2s transform ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background: #03c598;
    box-shadow: none;
  }
`;

export default function CTAButtonExternalLink(props) {
  return (
    <StyledBox>
      <a className="anchor-cont" href={`${props.hrefProp}`}>
        {/* <Link to="/#contact">Click Here</Link> */}
        <StyledButton variant="contained" onClick={props.onClick}>
          {props.text}
        </StyledButton>
      </a>
    </StyledBox>
  );
}
