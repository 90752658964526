import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import PortfolioCardDesktop from "../portfolioDesktop/PortfolioCardDesktop.js";
import PortfolioCardMobile from "../portfolioMobile/PortfolioCardMobile.js";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export default function PortfolioItemCavoodles(props) {
  const portfolioItem = {
    cardTitle: "Cavoodles",
    subtitle: "Lorem Ipsum",
    imgDesktop:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fcavoodles.jpg?alt=media&token=19c11f6e-064e-4c17-a7eb-976cf4c6a2a9",
    imgMobile:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fcavoodles-mobile.jpg?alt=media&token=fcb45f28-4b2e-4622-bda2-eec84b8918f7",
    imgMobile2: "",
    imgMobile3: "",
    tab1Title: "Brief",
    tab1Body: (
      <p>
        A new business and a clean slate, the client required a brand new site
        on a budget that could be easily modifid and maintained by the client
        themselves. <br />
        My responsibilies were; Design ideation, proposing technologies, theme
        search, design/theme modification, development, content population and
        deployment.
      </p>
    ),
    tab2Title: "Design",
    tab2Body: (
      <p>
        The client desired a "farm" like feel and color scheme. I achieved this
        with a pastel pink and other subdued colours, with the more saturated
        accent colour used sparingly for call to actions.
      </p>
    ),
    tab3Title: "Development",
    tab3Body: (
      <p>
        This site required very little custom functionality so the client and I
        decided to buy and modify an existing theme in the interest of kepping
        costs down.
        <br />
        Tech:
        <ul>
          <li>Wordpress</li>
          <li>DAWGs theme</li>
          <li>Elementor Pro</li>
          <li>Hosted and optimised on siteground</li>
          <li>Less than 2s page load time</li>
        </ul>
      </p>
    ),
    tab4Title: "Performance",
    tab4Body:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt mollit anim id est laborum.",
    displayImageOnMobile: "mobile or desktop",
    alt: "alt text",
    url: "https://www.chevalierhallcavoodles.com.au/"
  };

  return (
    <Fragment>
      <Desktop>
        <PortfolioCardDesktop
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          tab2Title={portfolioItem.tab2Title}
          tab2Body={portfolioItem.tab2Body}
          tab3Title={portfolioItem.tab3Title}
          tab3Body={portfolioItem.tab3Body}
          tab4Title={portfolioItem.tab4Title}
          tab4Body={portfolioItem.tab4Body}
          url={portfolioItem.url}
          alt={portfolioItem.alt}
        />
      </Desktop>
      <Mobile>
        <PortfolioCardMobile
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          tab2Title={portfolioItem.tab2Title}
          tab2Body={portfolioItem.tab2Body}
          tab3Title={portfolioItem.tab3Title}
          tab3Body={portfolioItem.tab3Body}
          tab4Title={portfolioItem.tab4Title}
          tab4Body={portfolioItem.tab4Body}
          displayImageOnMobile={portfolioItem.displayImageOnMobile}
          alt={portfolioItem.alt}
          index={props.index}
          activeIndex={props.activeIndex}
          url={portfolioItem.url}
          open={props.open}
        />
      </Mobile>
    </Fragment>
  );
}
