import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import PortfolioCardDesktop from "../portfolioDesktop/PortfolioCardDesktop.js";
import PortfolioCardMobile from "../portfolioMobile/PortfolioCardMobile.js";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export default function PortfolioItemBloomCampervans(props) {
  const portfolioItem = {
    cardTitle: "Bloom Campervans",
    subtitle: "Lorem Ipsum",
    imgDesktop:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fbloom-campervans.jpg?alt=media&token=cef5a267-7507-4d2f-8e06-3689fa60bd38",
    imgMobile:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fbloom-campervans-mobile.jpg?alt=media&token=6bb12a6d-7534-474f-b694-a5554402d729",
    imgMobile2: "",
    imgMobile3: "",
    tab1Title: "Overview",
    tab1Body: (
      <p>
        A personal project, built with Wordpress and Woocomerce. <br /> I took
        this site as an opportunity to experiment with the Divi pagebuilder.
      </p>
    ),
    tab2Title: "Design",
    tab2Body: (
      <p>A clean and minimal design. Designed to hero an inoovative an</p>
    ),
    tab3Title: "Development",
    tab3Body: <p></p>,
    tab4Title: "Performance",
    tab4Body:
      "Bloom 4 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt mollit anim id est laborum.",
    displayImageOnMobile: "desktop",
    alt: "alt text",
    url: "https://bloomcampervans.com/"
  };

  return (
    <Fragment>
      <Desktop>
        <PortfolioCardDesktop
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          tab2Title={portfolioItem.tab2Title}
          tab2Body={portfolioItem.tab2Body}
          tab3Title={portfolioItem.tab3Title}
          tab3Body={portfolioItem.tab3Body}
          tab4Title={portfolioItem.tab4Title}
          tab4Body={portfolioItem.tab4Body}
          url={portfolioItem.url}
          alt={portfolioItem.alt}
        />
      </Desktop>
      <Mobile>
        <PortfolioCardMobile
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          tab2Title={portfolioItem.tab2Title}
          tab2Body={portfolioItem.tab2Body}
          displayImageOnMobile={portfolioItem.displayImageOnMobile}
          alt={portfolioItem.alt}
          index={0}
          activeIndex={props.activePortfolioCard}
          url={portfolioItem.url}
          open={props.open}
        />
      </Mobile>
    </Fragment>
  );
}
