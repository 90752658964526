// theme.js
export const theme = {};

export const lightTheme = {
  fontPrimary: "'Poppins', sans-serif",
  fontSecondary: "",
  text: "#232323",
  textInverted: "white",
  primaryLight: "#232323",
  primaryHover: "#04fbc0",
  primaryDark: "#EFFFFA",
  accentLight: "#04fbc0",
  accentHover: "#03C99B",
  accentDark: "#02644D",
  backgroundLight: "white",
  backgroundLightTrans: "transparent",
  backgroundDark: "#F7F7F7",
  backgroundGrey: "#F2F2F2",
  backgroundDarkGrey: "#454545",
  backgroundControlPanels: "#E0E0E0",
  buttonBG: "#232323",
  cardBG: "#f2f2f2", //use for background color of cards
  mobile: "576px",
  shadow: "#bdbdbd",
  shadowSelected: "#e0e0e0",
  backgroundTransition: "0.2s background-color ease-out;"
};

export const darkTheme = {
  fontPrimary: "'Poppins', sans-serif",
  fontSecondary: "",
  text: "white",
  textInverted: "#232323",
  primaryLight: "#232323",
  primaryHover: "#04fbc0",
  primaryDark: "#EFFFFA",
  accentLight: "#04fbc0",
  accentHover: "#03C99B",
  accentDark: "#03c99b",
  backgroundLight: "#292929",
  backgroundLightTrans: "transparent",
  backgroundDark: "#F7F7F7",
  backgroundGrey: "#454545",
  backgroundDarkGrey: "#454545",
  backgroundControlPanels: "#3D3D3D",
  buttonBG: "white",
  cardBG: "#454545", //use for background color of cards
  mobile: "576px",
  shadow: "#292929",
  shadowSelected: "#333333",
  backgroundTransition: "0.2s background-color ease-out;"
};
