import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import CTAButtonExternalLink from "../../../buttons/CTAButtonExtenalLink";
import { Typography } from "@material-ui/core";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../../../FadeTransition.css";
import { Link } from "react-router-dom";

/* ----------------- Start Styles --------------------- */
const CardBox = styled(Box)`
  width: auto;
  height: auto;
  padding: 10vh 0;
  margin: 24px 20%;
  position: absolute;
  background-color: none; //currently covering overlap error
  @media screen and (max-height: 1080px) {
    padding: 6vh 10%;
  }
  @media screen and (max-height: 1000px) {
    padding: 2vh 10%;
  }
  @media screen and (max-height: 900px) {
    padding: 0vh 10%;
  }
  .portfolio-card-contents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .card-bg-portfolio {
    position: absolute;
    top: 8vh;
    width: 450px;
    height: 450px;
    background-color: ${({ theme }) => theme.backgroundGrey};
    border-radius: 0 40px 0 40px;
    z-index: 0;
    transition: ${(props) => props.theme.backgroundTransition};
  }

  .typography {
    position: relative;
    margin: 0 -48px;
    z-index: 2;
  }

  .portfolio-item-tabs {
    position: relative;
    z-index: 1220;
    margin-left: -24px;
  }

  .portfolio-item-tabs .MuiTabs-indicator {
    height: 48px;
    background-color: #04fbc0;
    border-radius: 2px;
  }

  .portfolio-item-tabs .MuiTab-root {
    position: relative;
    padding: 0px;
    color: ${(props) => props.theme.text};
    font-size: 14px;
    font-weight: bold;
    z-index: 1220;
    border-radius: 2px;
    transition: 0.25s all ease-out;
  }

  .portfolio-item-tabs .Mui-selected {
    color: #232323;
  }

  .portfolio-item-tabs .MuiTab-root:hover {
    position: relative;
    z-index: 1220;
    border-radius: 2px;
  }

  .portfolio-item-tabs .MuiTab-root.Mui-selected {
    /* background-color: #04fbc0; */
  }

  .tab-panel-final {
    position: absolute;
    z-index: 1220;
    padding: 18px 0 0 0;
    width: 70vw;
    /* max-width: 600px; */
  }

  .tab-panel {
    position: absolute;
    z-index: 1220;
    padding: 18px 0 0 0;
    width: 70vw;
    max-width: 600px;
  }

  .portfolio-card-contents-images ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  .portfolio-card-contents-images li {
    height: 40vh;
    flex-grow: 1;
  }
  // BASIC

  .portfolio-card-contents-images li:last-child {
    // There's no science in using "10" here. In all my testing, this delivered the best results.
    flex-grow: 10;
  }

  .portfolio-card-contents-images img {
    /* max-height: 100%;
    min-width: 250px; */
    object-fit: cover;
    vertical-align: bottom;
  }

  // ADVANCED

  // Portrait

  @media (max-aspect-ratio: 1/1) {
    .portfolio-card-contents-images li {
      height: 30vh;
    }
  }

  // Short screens

  @media (max-height: 480px) {
    .portfolio-card-contents-images li {
      height: 80vh;
    }
  }

  // Smaller screens in portrait

  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
    .portfolio-card-contents-images ul {
      flex-direction: row;
    }

    .portfolio-card-contents-images li {
      height: auto;
      width: 100%;
    }
    /* .portfolio-card-contents-images img {
      width: 100%;
      max-height: 75vh;
      min-width: 0;
    } */
  }

  /* Styles to lower the opacity of cards that are not currently selected */
  &.MuiBox-root {
    transition: all 0.3s ease;
  }
  /* &.MuiBox-root.is-selected {
    opacity: 1;
  } */

  .screenshot-container {
    display: flex;
  }

  .desktop-screen {
    position: relative;
    z-index: 3;
    width: 50vw;
    max-width: 625px;
    height: 30vw;
    max-height: 375px;
    object-fit: cover;
    object-position: 0px 0%;
    border-radius: 12px;
    border: 4px solid #232323;
    -webkit-transition: object-position 3s ease-in-out;
    -moz-transition: object-position 3s ease-in-out;
    -ms-transition: object-position 3s ease-in-out;
    -o-transition: object-position 3s ease-in-out;
    transition: object-position 3s ease-in-out;
  }
  @media screen and (min-width: 768px) {
    .desktop-screen {
      position: relative;
      z-index: 3;
      width: 50vw;
      max-width: 400px;
      height: 30vw;
      max-height: 250px;
      object-fit: cover;
      object-position: 0px 0%;
      border-radius: 12px;
      border: 4px solid #232323;
      -webkit-transition: object-position 3s ease-in-out;
      -moz-transition: object-position 3s ease-in-out;
      -ms-transition: object-position 3s ease-in-out;
      -o-transition: object-position 3s ease-in-out;
      transition: object-position 3s ease-in-out;
    }
  }
  .desktop-screen:hover {
    object-position: 0px 100%;
  }

  .mobile-screen {
    position: relative;
    z-index: 3;
    width: 20vw;
    max-width: 250px;
    height: 40vw;
    max-height: 500px;
    margin: 0 6px;
    object-fit: cover;
    object-position: 0px 0%;
    border-radius: 12px;
    border: 4px solid #232323;
    -webkit-transition: object-position 3s ease-in-out;
    -moz-transition: object-position 3s ease-in-out;
    -ms-transition: object-position 3s ease-in-out;
    -o-transition: object-position 3s ease-in-out;
    transition: object-position 3s ease-in-out;
  }

  @media screen and (min-width: 768px) {
    .mobile-screen {
      position: relative;
      z-index: 3;
      width: 20vw;
      max-width: 160px;
      height: 40vw;
      max-height: 320px;
      margin: 0 6px;
      object-fit: cover;
      object-position: 0px 0%;
      border-radius: 12px;
      border: 4px solid #232323;
      -webkit-transition: object-position 3s ease-in-out;
      -moz-transition: object-position 3s ease-in-out;
      -ms-transition: object-position 3s ease-in-out;
      -o-transition: object-position 3s ease-in-out;
      transition: object-position 3s ease-in-out;
    }
  }
  .mobile-screen:hover {
    object-position: 0px 100%;
  }

  .screens-container {
    display: flex;
  }

  .portfolio-typography-container {
    z-index: 3;
  }

  .screens-container {
    position: relative;
    margin-left: -50%;
  }

  /* Type Styles */
  .MuiTypography-h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: ${(props) => props.theme.text};
    text-align: left;
    margin-bottom: 8px;
    /* white-space: nowrap; */
  }
  .MuiTypography-subtitle1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 0 0 12px 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
  }
  .MuiTypography-body1 p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
  }
  .MuiTypography-body1 {
    margin: 0 24px;
  }
  p {
    margin: 0;
  }
  .title-link {
    display: flex;
  }
  .tab-panel {
    color: ${(props) => props.theme.text};
  }
  .link-to-project {
    color: ${(props) => props.theme.text};
    background: none;
    margin: 0;
    width: 24px;
  }
`;
/* ----------------- End Styles --------------------- */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function PortfolioCardDesktop(props) {
  const [value, setValue] = React.useState(0);
  const [step, setStep] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStep(step + 1);
  };

  return (
    <CardBox className="portfolio-item-container">
      <div class="card-bg-portfolio" />
      <div class="typography">
        <Box className="portfolio-typography-container">
          <div className="title-link">
            <Typography variant="h2">{props.cardTitle}</Typography>
            <IconButton
              aria-label="link to project"
              className="link-to-project"
              href={props.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkIcon fontSize="inherit" />
            </IconButton>
          </div>
          {/* <Typography variant="subtitle1">{props.subtitle}</Typography> */}
        </Box>
      </div>
      <div class="portfolio-item-tabs">
        <Tabs
          className="portfolio-item-tabs"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Final" {...a11yProps(0)} />
          {props.tab1Title ? (
            <Tab label={props.tab1Title} {...a11yProps(1)} />
          ) : null}{" "}
          {props.tab2Title ? (
            <Tab label={props.tab2Title} {...a11yProps(2)} />
          ) : null}{" "}
          {props.tab3Title ? (
            <Tab label={props.tab3Title} {...a11yProps(3)} />
          ) : null}{" "}
          {/* <Tab label={props.tab4Title} {...a11yProps(4)} /> */}
        </Tabs>
      </div>
      <TransitionGroup>
        {/* no different than other usage of
                CSSTransition, just make sure to pass
                `location` to `Switch` so it can match
                the old location as it animates out
            */}
        <CSSTransition key={step} timeout={200} classNames="fade">
          <Box>
            <TabPanel className="tab-panel-final" value={value} index={0}>
              <Box className="portfolio-card-contents-images">
                <div className="screenshot-container">
                  <img
                    className="mobile-screen"
                    translatex={props.translateHor}
                    translatey={props.translateVert}
                    src={props.imgMobile}
                    scale={props.scale}
                    alt={""}
                  />
                  <div>
                    <img
                      className="desktop-screen"
                      translatex={props.translateHor}
                      translatey={props.translateVert}
                      src={props.imgDesktop}
                      scale={props.scale}
                      alt={""}
                    />
                  </div>
                </div>
              </Box>
            </TabPanel>
            <TabPanel className="tab-panel" value={value} index={1}>
              <Typography variant="body1">{props.tab1Body}</Typography>
            </TabPanel>
            <TabPanel className="tab-panel" value={value} index={2}>
              <Typography variant="body1">{props.tab2Body}</Typography>
            </TabPanel>
            <TabPanel className="tab-panel" value={value} index={3}>
              <Typography variant="body1">{props.tab3Body}</Typography>
              <CTAButtonExternalLink
                text="View Site >"
                hrefProp="http://brandedglassware.com.au/"
              />
            </TabPanel>
            {/* <TabPanel className="tab-panel" value={value} index={4}>
              {props.tab4Body}
            </TabPanel> */}
          </Box>
        </CSSTransition>
      </TransitionGroup>
    </CardBox>
  );
}
