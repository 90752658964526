import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { Typography } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import DarkModeSwitch from "../buttons/DarkModeSwitch.js";
import TopOrSideNavSwitch from "../buttons/TopOrSideNavSwitch.js";

import LinkIcon from "@material-ui/icons/Link";
import ProgressDial from "./ProgressDial.js";

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  position: absolute;
  z-index: 2100;
  .MuiDrawer-root {
    position: absolute;
    z-index: 2100;
  }
  .MuiDrawer-paper {
    position: absolute;
    width: ${(props) => (props.isDesktop ? "50vw" : "100vw")};
    border-radius: ${(props) => (props.isDesktop ? "40px 0 0 40px" : "0")};
    background-color: ${(props) => props.theme.backgroundGrey};
    padding: 48px 24px;
    color: ${(props) => props.theme.text};
    transition: 0.2s background-color ease-out, 0.2s color ease-out;
    z-index: 2100;
  }
  .close-drawer {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .chips-container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 8px 0 0 0;
  }
  .chip {
    width: fit-content;
    margin: 3px;
    padding: 10px 12px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 36px;
  }
`;

const StyledBox = styled(Box)`
  padding: 0px 36px;
  .list {
    margin: 0;
  }
  .MuiTypography-h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    padding: 0 0 12px 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
    /* white-space: nowrap; */
  }
  .MuiTypography-h3 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
    /* white-space: nowrap; */
  }
  .MuiTypography-subtitle1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 0 0 12px 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
  }
  .MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
  }
  .li-container {
    display: flex;
    justify-content: start;
    align-items: center;
    max-height:28px;
  }
  .performance-title {
    margin: 18px 0;
  }
  .link-to-github{
    /* background-color: ${(props) => props.theme.backgroundDarkGrey}; */
    color: ${(props) => props.theme.text};
  }
  .github-logo{
    width:24px;
    height: auto;
    fill: ${(props) => props.theme.text};
    transition: 0.2s fill ease-out;
  }
  .progress-dial{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .progress-label{
    margin: 6px 0 0 0 ;
  }
  .MuiCircularProgress-root{
    color:#04fbc0;
  }
  .audit-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .audit-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:0px;
  }
  @media screen and (min-width: 768px) {
    .audit-row {
    flex-direction: column;
    align-items: center;
    margin:0px;
  }
  }
  @media screen and (max-width: 585px) {
    .audit-row {
    flex-direction: column;
    align-items: center;
    margin:0px;
  }
  }
`;

export default function InfoDrawer(props) {
  const { themeToggler, isDesktop } = useContext(MainContext);

  const [tech, setTech] = useState([
    "HTML, CSS, JS",
    "React",
    "PWA",
    "Anime.js",
    "Material UI",
    "styled-components",
    "SVG Animation",
    "Firebase",
    "Vercel",
    "Figma"
  ]);

  return (
    <StyledSwipeableDrawer
      className="about-overlay"
      isDesktop={isDesktop}
      anchor="right"
      open={props.drawerOpen}
      onClose={() => {
        props.toggleDrawer();
      }}
      onOpen={() => {
        props.toggleDrawer();
      }}
    >
      <IconButton
        aria-label="close"
        className="close-drawer"
        onClick={() => {
          props.toggleDrawer();
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <StyledBox>
        <Typography variant="h2" gutterBottom>
          About this site.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Built from the ground up, this site's purpose is to;
          <ol className="list">
            <li>
              <b>Communicate</b> quickly what I do to potential clients and
              employers
            </li>
            <li>
              <b>Impress</b> by demonstrating my skills and the practical
              awareness with which I use them
            </li>
            <li>
              <b>Convert</b> the potential client or employer by having them
              take the next step and contact me
            </li>
          </ol>
        </Typography>
        <Typography variant="h3" gutterBottom>
          Features:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <ul className="list">
            <li>Streamlined User Experience</li>
            <li>Extremely fast load time</li>
            <li>Functional Components, Hooks, Context API</li>
            <li>
              <div className="li-container">
                Dark Mode --> <DarkModeSwitch margin="0 0 0 12px" />
              </div>
            </li>
            <li>Automatically detect if user prefers Dark Mode</li>
            <li>PWA</li>
            <li>
              <div className="li-container">
                View the code ->{" "}
                <IconButton
                  aria-label="link to GitHub"
                  className="link-to-github"
                  href="https://github.com/joshmamo/joshmamo.com-Interim-Portfolio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <LinkIcon fontSize="inherit" /> */}
                  <SVG className="github-logo" src="/svg/github-logo.svg" />
                </IconButton>
              </div>
            </li>
            <li>
              <div className="li-container">
                Top or Side Nav --> <TopOrSideNavSwitch margin="0 0 0 12px" />
              </div>
            </li>
          </ul>
        </Typography>
        <Typography variant="h3" gutterBottom>
          Technologies used:
        </Typography>
        <Box className="chips-container">
          {tech.map((item, i) => (
            <Chip
              key={i}
              className="chip"
              label={item}
              onClick={() => {
                console.log(item);
              }}
            />
          ))}
        </Box>
        <Typography className="performance-title" variant="h3" gutterBottom>
          Performance:
        </Typography>
        <div className="audit-container">
          <div className="audit-row">
            <ProgressDial value="100" label="Performance" />
            <ProgressDial value="100" label="Accessibility" />
          </div>
          <div className="audit-row">
            <ProgressDial value="100" label="Best Practices" />
            <ProgressDial value="100" label="SEO" />
          </div>
        </div>
      </StyledBox>
    </StyledSwipeableDrawer>
  );
}
