import React from "react";

import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";

const StyledIconButton = styled(IconButton)`
  box-shadow: none;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  color: #232323;
  background-color: ${(props) => props.theme.accentLight};
  align-self: center;
  transform: ${(props) => (props.open ? "rotate(-180deg)" : "rotate(0deg)")};
  transition: 0.2s transform ease-out, 0.2s background-color ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.accentHover};
    color: #232323;
  }
  @media screen and (max-width: 767px) {
  }
`;

export default function ExpandButton(props) {
  return (
    <StyledIconButton
      aria-label="back to all"
      className="back-button"
      margin={props.margin}
      padding={props.padding}
      onClick={props.onClick}
      open={props.open}
    >
      <ExpandMoreIcon fontSize={props.size} />
    </StyledIconButton>
  );
}
