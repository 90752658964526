import React, { useContext } from "react";
import { MainContext } from "../../../contexts/MainContext.js";
import Box from "@material-ui/core/Box";
import PortfolioMainFlickity from "./PortfolioMainFlickity";
import PortfolioThumbnailFlickity from "./PortfolioThumbnailFlickity";

import "flickity/dist/flickity.min.css";
import "flickity-as-nav-for";
import BackButton from "../../../buttons/BackButton.js";

export default function PortfolioPageDesktop() {
  const { portfolioItems } = useContext(MainContext);

  return (
    <Box className="portfolio-page-container">
      {/* <Box className="empty-top" /> */}
      {/* <BackButton margin="0 0 0 24px" /> */}

      {/* Go one layer deeper, into "PortfolioMainFlickity" to add portfolio items */}
      <PortfolioMainFlickity portfolioItems={portfolioItems} />
      <PortfolioThumbnailFlickity portfolioItems={portfolioItems} />
    </Box>
  );
}
