import React, { useContext } from "react";
import { MainContext } from "../../../contexts/MainContext.js";
import Box from "@material-ui/core/Box";
import Flickity from "react-flickity-component";

export default function PortfolioThumbnailFlickity(props) {
  const { portfolioItems, setPortfolioItems } = useContext(MainContext);

  const thumbnails = [
    "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fengraved.jpg?alt=media&token=8ac3a231-4ea3-4886-9951-a515612cd3c6",
    "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fbrandedglassware.jpg?alt=media&token=59362de7-9b66-46d0-8afb-721b76bda741",
    "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fcavoodles.jpg?alt=media&token=19c11f6e-064e-4c17-a7eb-976cf4c6a2a9",
    "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fbloom-campervans.jpg?alt=media&token=cef5a267-7507-4d2f-8e06-3689fa60bd38",
    "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fcafepennoz.jpg?alt=media&token=ab7c22ef-4c5d-4e6b-a186-b6059cc25805"
  ];

  // const selectSlide = (index) => {
  //   //setIndexCurrent(index);
  //   this.flkty.select(index);
  //   console.log("test2" + index);
  //   console.log(this.flkty);
  // };

  const flickityOptions = {
    asNavFor: ".portfolio-main-flickity",
    contain: true,
    pageDots: false
    // prevNextButtons: false
    // on: {
    //   change: function (index) {
    //     console.log("Slide changed to" + index);
    //     selectSlide(index);
    //     console.log("test" + index);
    //   }
    // }
  };

  return (
    <Box id="thumbnails-bottom-bar">
      <Flickity
        // flickityRef={(ref1) => (this.flkty = ref1)}
        className="portfolio-thumb-selector-flickity"
        asNavFor=".portfolio-main-flickity"
        contain={true}
        options={flickityOptions}
        // key="flickity2"
      >
        {thumbnails.map((thumbnail) => (
          <Box
            className="portfolio-thumbnail-container"
            // onClick={() => selectSlide(0)}
          >
            <img
              className="portfolio-thumbnail"
              src={thumbnail}
              alt="{thumbnail.alt}"
            />
            <div className="portfolio-thumbnail-shadow" />
          </Box>
        ))}
      </Flickity>
      <Box id="bottom-grey-bar" />
    </Box>
  );
}
