import React, { Fragment, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const CardBox = styled(Box)`
  display: flex;
  width: auto;
  height: 325px;
  background-color: none;
  border-radius: 0 40px 0 40px;
  margin-right: 80px;
  .unexpanded-card {
    width: auto;
  }
  /* Styles to lower the opacity of cards that are not currently selected */
  &.MuiBox-root {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &.MuiBox-root.is-selected {
    background-color: ${(props) => props.theme.cardBG};
    opacity: 1;
  }
  /* Type Styles */
  .MuiTypography-h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: ${(props) => props.theme.text};
    margin-left: 24px;
    text-align: left;
    /* white-space: nowrap; */
    margin-top: ${(props) => (props.marginTopH2 ? props.marginTopH2 : "0%")};
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &.MuiBox-root.is-selected .MuiTypography-h2 {
    opacity: 1;
    color: ${(props) => props.theme.text};
  }
  .MuiTypography-subtitle1 {
    font-family: "Open-Sans ", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: ${(props) => props.theme.text};
    text-align: left;
    margin-left: 24px;
    opacity: 0;
    transition: all 0.3s ease;
  }
  &.MuiBox-root.is-selected .MuiTypography-subtitle1 {
    opacity: 1;
  }
  .MuiTypography-body1 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
    opacity: ${(props) => (props.cardExpanded ? 1 : 0)};
    width: ${(props) => (props.cardExpanded ? "600px" : "325px")};
    transition: all 0.3s ease;
    /* opacity: ${(props) => (props.activeIndex === props.index ? "1" : "0")}; ;
    transition: 0.5s opacity cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
    /* opacity: ${(props) => (props.activeIndex === props.index ? 1 : 0)}; */
  }
  &.MuiBox-root.is-selected .MuiTypography-body1 {
    /* opacity: ${(props) => (props.expanded ? 1 : 0)}; */

  }
  .svg-container-wid {
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &.MuiBox-root.is-selected .svg-container-wid {
    opacity: 1;
  }
`;

const BodyBox = styled(Box)`
  /* scrollbar styles */
  /* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
  overflow-y: scroll;
  max-width:  ${(props) => (props.expanded ? "500px" : "0px")};
  /* transform-origin: 100% 0%;
  transform: ${(props) =>
    props.activeIndex === props.index ? "scaleY(1)" : "scaleY(0)"}; */
  transition:  0.8s max-height cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 90%;
  margin: 0 auto;
  background-color: #f2f2f2;
    .MuiTypography-body1 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #4f4f4f;
    text-align: left;
    /* opacity: ${(props) => (props.activeIndex === props.index ? "1" : "0")}; ;
    transition: 0.5s opacity cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
    /* opacity: ${(props) => (props.activeIndex === props.index ? 1 : 0)}; */
  }
`;

const StyledSVG = styled(SVG)`
  position: relative;
  overflow: visible;
  left: 0px;
  top: 0px;
  width: ${(props) => (props.scale ? props.scale : "auto")};
  height: ${(props) => (props.scale ? props.scale : "auto")};
  transform: ${(props) =>
    props.translateX && props.translateY
      ? `translate(${props.translateX}, ${props.translateY})`
      : "translate(0,0)"};
`;

export default function CarouselCard(props) {
  const [cardSelected, setCardSelected] = useState(false);
  const [cardExpanded, setCardExpanded] = useState(false);

  function ToggleCardOpen() {
    setCardExpanded(!cardExpanded);
    console.log(cardExpanded);
    console.log(props.index);
  }

  // useEffect(() => {
  //   props.selectedIndex === props.index
  //     ? setCardSelected(true)
  //     : setCardSelected(false);
  //   console.log(props.index);
  // }, [props.selectedIndex, props.index]);

  return (
    <CardBox
      className="card-bg"
      cardExpanded={cardExpanded}
      marginTopH2={props.marginTopH2}
      onClick={props.onClick}
    >
      <Box className="unexpanded-card">
        <Box className="svg-container-wid">
          <StyledSVG
            translateX={props.translateHor}
            translateY={props.translateVert}
            src={props.svgSrc}
            scale={props.scale}
          />
        </Box>
        <IconButton
          aria-label="expand-read-more"
          size="small"
          onClick={() => {
            ToggleCardOpen();
          }}
        >
          <ChevronRightIcon fontSize="inherit" />
        </IconButton>
        <Typography variant="h2" gutterBottom>
          {props.cardTitle}
        </Typography>
        {/* <Typography variant="subtitle1" gutterBottom>
          {props.subtitle}
        </Typography> */}
        <Typography variant="body1" gutterBottom>
          {props.body}
        </Typography>
      </Box>
      {/* <BodyBox
        activeIndex={props.activeIndex}
        index={props.index}
        expanded={cardExpanded}
      >
        <Typography variant="body1" gutterBottom>
          {props.body}
        </Typography>
      </BodyBox> */}
    </CardBox>
  );
}
