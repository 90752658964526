import React from "react";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

const StyledBox = styled(Box)`
  margin: 0 8px 12px;
  .progress-dial {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .progress-label {
    margin: 6px 0 0 0;
  }
  .MuiCircularProgress-root {
    color: #04fbc0;
  }
`;

export default function ProgressDial(props) {
  return (
    <StyledBox className="progress-dial">
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="static"
          value={props.value}
          size={80}
          thickness={4}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body1" component="div" color="textSecondary">
            {props.value}%
          </Typography>
        </Box>
      </Box>
      <Typography
        className="progress-label"
        variant="body1"
        component="div"
        color="textSecondary"
      >
        {props.label}
      </Typography>
    </StyledBox>
  );
}
