import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.primaryLight};
  height: 100vh;
  width: 100%;
  text-align: left;
  padding: 1rem 2rem;
  position: absolute;
  top: 0;
  left: 100vw;
  /* Set how much of the screen is covered by menu overlay */
  transform: ${({ open }) => (open ? "translateX(-100vw)" : "translateX(0)")};
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  .active-mobile {
    color: ${({ theme }) => theme.primaryHover};
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 1rem 0;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    text-align: right;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.3rem;
      text-align: right;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }

  .MuiButton-label {
    justify-content: flex-end;
  }

  .MuiButtonBase-root {
    font-size: 2rem;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 1rem 0;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    text-align: right;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.3rem;
      text-align: right;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
      background-color: transparent;
    }
  }
`;
