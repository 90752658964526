import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { SignalCellularNull } from "@material-ui/icons";

const StyledButton = styled(Button)`
  &.cta-button {
    background-color: #04fbc0;
    border-radius: 2px;
    box-shadow: none;
    margin: ${(props) => props.margin};
    padding: 12px 24px;
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    color: #232323;
    transform: scale(1);
    transition: 0.2s transform ease-out;
  }
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background: #03c598;
    box-shadow: none;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin: ${(props) => props.margin};
  }
`;

export default function CTAButton(props) {
  return (
    <StyledButton
      className="cta-button"
      variant="contained"
      onClick={props.onClick}
      component={props.linkTo ? Link : "null"}
      to={props.linkTo}
      margin={props.margin}
    >
      {props.text}
    </StyledButton>
  );
}
