import React, { createContext, useState, useEffect } from "react";

export const MainContext = createContext();

const MainContextProvider = (props) => {
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 767);
  const [theme, setTheme] = useState("light");
  const [topOrSideNav, setTopOrSideNav] = useState(true); //true sets top nav as default

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const themeToggler = (arg) => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  const navToggler = (arg) => {
    setTopOrSideNav(!topOrSideNav);
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <MainContext.Provider
      value={{
        portfolioItems,
        setPortfolioItems,
        isDesktop,
        theme,
        setTheme,
        themeToggler,
        navToggler,
        topOrSideNav,
        setTopOrSideNav,
        drawerOpen,
        setDrawerOpen,
        toggleDrawer
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
