import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SVG from "react-inlinesvg";
import TextField from "@material-ui/core/TextField";
import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CheckboxButton from "../../buttons/CheckboxButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import copy from "copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";

import { FiCopy } from "react-icons/fi";

import styled from "styled-components";

const StyledBox = styled(Box)`
  position: absolute;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 80px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.backgroundTransition};
  @media screen and (min-width: 767px) {
    padding: 0;
  }
  .input-form {
    margin: 12px;
    width: 50vw;
    min-width: 300px; /* Fix this with a media query */
    max-width: 550px;
    background-color: ${(props) => props.theme.backgroundGrey};
    border-radius: 4px;
    transition: 0.2s background-color ease-out;
  }

  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => `2px solid ${props.theme.backgroundGrey}`};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid #04fbc0;
    transition: 0.2s border ease-out;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #04fbc0 !important;
  }

  .MuiInputBase-root,
  .MuiInputBase-input,
  .MuiFormLabel-root {
    color: ${(props) => props.theme.text} !important;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.2s all ease-out;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #232323;
  }

  /* Type Styles */
  .MuiTypography-h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    padding: 0 0 4px 0;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
    transition: 0.2s color ease-out;
    /* white-space: nowrap; */
  }
  .MuiTypography-subtitle1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 0 0 12px 0;
    color: ${(props) => props.theme.text};
    text-align: center;
    z-index: 20;
    transition: 0.2s color ease-out;
  }
  .MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
    z-index: 20;
  }
  .MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 6px 0;
  }

  .submit-button {
    background-color: #04fbc0;
    color: #232323;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    box-shadow: none;
    margin: 12px;
    padding: 12px 24px;
    transition: 0.1s background-color ease-out, 0.1s border ease-out;
    :hover {
      background-color: #03c99b;
    }
  }
  .submit-button .MuiButton-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .svg-loader {
    margin-left: 12px;
  }

  /* .Mui-disabled{
    background-color: #F1F1F1;
  } */
  .email-link-container {
    display: flex;
    justify-content: center;
    width: fit-content;
    align-content: center;
    margin: 12px 0 12px 0;
  }
  .email-link {
    color: ${(props) => props.theme.text};
    max-width: 100%;
    background: none;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  .snackbar .MuiPaper-root {
    background-color: ${(props) => props.theme.accentLight};
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    box-shadow: none;
  }
  .icon-copy {
    color: ${(props) => props.theme.text};
    width: fit-content;
    padding: 10px;
    background-color: transparent;
    margin: -5px 6px 0;
    border-radius: 100px;
    transition: 0.2s background-color ease-out;
  }
  .icon-copy:hover {
    background-color: rgba(36, 36, 36, 0.2);
  }
`;

export default function ContactPage() {
  const [submitButtonText, setSubmitButtonText] = useState("Submit");
  const [open, setOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const textAreaRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    company: "",
    email: "",
    phone: "",
    services: "",
    projectDescription: ""
  });
  const [servicesToggles, setServicesToggles] = useState({
    websiteDesign: true,
    appDesignDev: false,
    webPerformance: false
  });

  const handleClose = () => {
    setOpen(false);
  };

  const preventDefault = (event) => event.preventDefault();

  const handleChangeToggles = (event) => {
    setServicesToggles({
      ...servicesToggles,
      [event.target.name]: event.target.checked
    });
  };

  const handleChangeText = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateFormData = () => {
    const newFormData = { ...formData };
    newFormData.services = servicesToggles;
    setFormData(newFormData);
  };

  useEffect(() => {
    updateFormData();
  }, [servicesToggles]);

  const handleSubmit = (event) => {
    const { fullName, company, email, phone, projectDescription } = formData;
    const services = Object.keys(servicesToggles);
    const filteredServices = services.filter(function (service) {
      return servicesToggles[service];
    });
    event.preventDefault();
    setSubmitting(true);
    setSubmitButtonText("Submitting");
    axios
      .post(
        "https://usebasin.com/f/f20e81b28003",
        {
          fullName,
          company,
          email,
          phone,
          projectDescription,
          filteredServices
        }
        // { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        setSubmitting(false);
        setSubmitButtonText("Message Sent");
        setFormData({
          fullName: "",
          company: "",
          email: "",
          phone: "",
          services: "",
          projectDescription:
            "Thank you for your enquiry, I will be in touch soon."
        });
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        setSubmitting(false);
        setSubmitButtonText("Error Sending Message");
      });
  };

  return (
    <StyledBox>
      <Typography variant="h2">Get in Touch</Typography>
      <Typography variant="subtitle1">
        Please select one or more services you are interested in:
      </Typography>

      <FormGroup row>
        <CheckboxButton
          label="Website Design/Dev."
          checked={servicesToggles.websiteDesign}
          name="websiteDesign"
          onChange={handleChangeToggles}
        />
        <CheckboxButton
          label="App Design/Dev."
          checked={servicesToggles.appDesignDev}
          name="appDesignDev"
          onChange={handleChangeToggles}
        />
        <CheckboxButton
          label="Website Performance"
          checked={servicesToggles.webPerformance}
          name="webPerformance"
          onChange={handleChangeToggles}
        />
      </FormGroup>
      <TextField
        className="input-form"
        id="outlined-basic"
        label="Full Name"
        variant="outlined"
        value={formData.fullName}
        name="fullName"
        onChange={handleChangeText}
      />
      <TextField
        className="input-form"
        id="outlined-basic"
        label="Company"
        variant="outlined"
        value={formData.company}
        name="company"
        onChange={handleChangeText}
      />
      <TextField
        className="input-form"
        id="outlined-basic"
        label="Email"
        variant="outlined"
        value={formData.email}
        name="email"
        onChange={handleChangeText}
      />
      <TextField
        className="input-form"
        id="outlined-basic"
        label="Phone"
        variant="outlined"
        value={formData.phone}
        name="phone"
        onChange={handleChangeText}
      />
      <TextField
        className="input-form"
        id="outlined-multiline-static"
        label="Project Description"
        multiline
        rows={4}
        variant="outlined"
        value={formData.projectDescription}
        name="projectDescription"
        onChange={handleChangeText}
      />
      <form onSubmit={handleSubmit}>
        <Button
          className="submit-button"
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitDisabled}
        >
          {submitButtonText}
          {submitting ? (
            <svg
              className="svg-loader"
              version="1.1"
              id="loader-1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="24px"
              height="24px"
              viewBox="0 0 50 50"
            >
              <path
                fill="#232323"
                d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.6s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          ) : null}
        </Button>
      </form>
      <Typography variant="body1">or</Typography>
      <div className="email-link-container">
        <Typography variant="body1">
          <Link
            className="email-link"
            id="email-copy"
            href="mailto: josh@joshmamo.com"
            //onClick={preventDefault}
            ref={textAreaRef}
          >
            email me - josh@joshmamo.com
          </Link>
        </Typography>
        <IconButton
          aria-label="copy email address"
          className="icon-copy"
          onClick={() => {
            copy("josh@joshmamo.com");
            setOpen(true);
          }}
          Text
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiCopy className="icon" />
        </IconButton>
      </div>
      <Snackbar
        className="snackbar"
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        message="Email address copied to clipboard :)"
        key={"bottom center"}
      />
    </StyledBox>
  );
}
