import React, { Fragment, useContext } from "react";
import MetaTags from "react-meta-tags";
import { MainContext } from "../../contexts/MainContext.js";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import CTAButton from "../../buttons/CTAButton";
import TextLoop from "react-text-loop";

const StyledBoxBG = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.backgroundLight};
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s background-color ease-out;
`;

const StyledBoxBG1 = styled(Box)`
  width: 45%;
  height: 100%;
  right: 0;
  border-radius: 40px 0 0 40px;
  position: absolute;
  background-color: ${(props) => props.theme.backgroundGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s background-color ease-out;
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 50%;
    bottom: 0;
    border-radius: 40px 40px 0 0;
  }
`;

const StyledBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .grid-container {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
    gap: 1px 1px;
    grid-template-areas: "atf-copy-grid-cont atf-svg-grid-cont";
  }
  .atf-svg-grid-cont {
    grid-area: atf-svg-grid-cont;
  }
  .atf-copy-grid-cont {
    grid-area: atf-copy-grid-cont;
  }
  @media only screen and (max-width: 767px) {
    .grid-container {
      display: grid;
      padding-top: 100px;
      height: calc(100%-100px);
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      gap: 1px 1px;
      grid-template-areas: "atf-copy-grid-cont" "atf-svg-grid-cont";
    }
    .atf-svg-grid-cont {
      grid-area: atf-svg-grid-cont;
    }
    .atf-copy-grid-cont {
      grid-area: atf-copy-grid-cont;
    }
  }
`;

const TextContainerBox = styled(Box)`
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  left: 20%;
  max-width: 450px;
  z-index: 100;
  /* Type Styles */
  @media screen and (max-width: 767px) {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    left: 48px;
    height: auto;
    max-width: calc(100vw - 70px);
    z-index: 100;
    .MuiTypography-h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin-bottom: 8px;
      /* white-space: nowrap; */
    }
    .MuiTypography-body1 {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin: 16px 0;
    }
  }
  @media screen and (min-width: 768px) {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 40%;
    left: 48px;
    height: auto;
    max-width: calc(100vw - 70px);
    z-index: 100;
    .MuiTypography-h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin-bottom: 8px;
      /* white-space: nowrap; */
    }
    .MuiTypography-body1 {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin: 16px 0;
    }
  }

  @media screen and (max-width: 480px) {
    left: calc(24px + 2.5vw);
  }
  .button-box {
    display: flex;
  }
`;

const StyledSVGBox = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledSVG = styled(SVG)`
  width: 100%;
  max-width: 900px;
  height: auto;
`;

export default function WhoPage() {
  const { toggleDrawer } = useContext(MainContext);
  return (
    <Fragment>
      <MetaTags>
        <title>Web Designer & Developer Sydney | Joshua Mamo</title>
        <meta
          name="description"
          content="A friendly and professional freelance web designer & developer in Sydney. Specialising in fast, modern sites with excellent user experiences."
        />
      </MetaTags>
      <StyledBoxBG className="bg-box" />
      <StyledBoxBG1 className="bg-box" />
      <StyledBox className="atf-page-container">
        <div class="grid-container">
          <div class="atf-copy-grid-cont">
            {" "}
            <TextContainerBox>
              <Typography className="h1-mobile" variant="h1" gutterBottom>
                I{"  "}
                <TextLoop
                  interval={1000}
                  // springConfig={{ stiffness: 180, damping: 16 }}
                  children={[
                    "build",
                    "design",
                    "optimize",
                    "animate",
                    "deploy",
                    "code",
                    "review",
                    "audit",
                    "maintain"
                  ]}
                />{" "}
                websites.
              </Typography>
              <Typography variant="body1" gutterBottom>
                {" "}
                With a particular penchent for designing and developing
                streamlined user interfaces.
              </Typography>
              <div className="button-box">
                <CTAButton
                  // href="/portfolio"
                  className="cta-button-portfolio"
                  margin="6px 12px 6px 0"
                  text="My Work"
                  linkTo="/portfolio"
                />
                <CTAButton
                  // href="/portfolio"
                  className="cta-button-this-site"
                  margin="6px 12px 6px 0"
                  text="This Site"
                  linkTo={""}
                  onClick={toggleDrawer}
                />
              </div>
            </TextContainerBox>
            {/* <TextContainerBox>
              <Typography variant="h1" gutterBottom>
                I build websites 
              </Typography>
              <Typography variant="body1" gutterBottom>
                Overview... Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod, ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod
              </Typography>
            </TextContainerBox> */}
          </div>
          <div class="atf-svg-grid-cont">
            <StyledSVGBox className="svgbox-ident">
              <StyledSVG
                src="/svg/web-development-illustration.svg"
                viewBox="0 0 901 676"
                preserveAspectRatio="xMidYMid meet"
              />
            </StyledSVGBox>
          </div>
        </div>
      </StyledBox>
    </Fragment>
  );
}
