import React from "react";
import ReactDOM from "react-dom";
import MainContextProvider from "./contexts/MainContext.js";
import { StylesProvider } from "@material-ui/core/styles";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Favicon from "react-favicon";
import WebFont from "webfontloader";

import ReactGA from "react-ga";
// import auth from './auth.ts'; // Sample authentication provider

ReactGA.initialize("UA-176070772-1", {
  debug: false,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});

WebFont.load({
  google: {
    families: [
      "Poppins:300,400,500,600,700,800",
      "Open Sans:300,400,600,800",
      "sans-serif"
    ]
  }
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <MainContextProvider>
        <Favicon url="https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/icons%2Ffavicon.ico?alt=media&token=723d3f0d-ae31-4465-a0bd-0bd0e5c2c311" />
        <App />
      </MainContextProvider>{" "}
    </StylesProvider>
  </React.StrictMode>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
