import React, { Fragment, useEffect, useState, useContext } from "react";
import { MainContext } from "../../contexts/MainContext.js";
import { useMediaQuery } from "react-responsive";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { StyledPortfolioPage } from "./PortfolioPage.styled";
import PortfolioPageMobile from "./portfolioMobile/PortfolioPageMobile";
import PortfolioPageDesktop from "./portfolioDesktop/PortfolioPageDesktop.js";

/*ONCE THE FULL DESKTOP VERSION OF PAGE IS COMPLETE - Create a video & GIF of it and 
put it up on Dribbble and other platforms for some publicity*/

import PortfolioItemEngraved from "./portfolioItems/PI-Engraved";
import PortfolioItemJoshmamo from "./portfolioItems/PI-Joshmamo";
import PortfolioItemBrandedGW from "./portfolioItems/PI-BrandedGW";
import PortfolioItemBloomCampervans from "./portfolioItems/PI-BloomCampervans.js";
import PortfolioItemCafePennoz from "./portfolioItems/PI-CafePennoz.js";
import PortfolioItemCavoodles from "./portfolioItems/PI-Cavoodles.js";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 }); //was 768
  return isDesktop ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 }); //was 767
  return isMobile ? children : null;
};

export default function PortfolioPage() {
  const { portfolioItems, setPortfolioItems } = useContext(MainContext);

  useEffect(() => {
    setPortfolioItems([
      <PortfolioItemEngraved />,
      <PortfolioItemBrandedGW />,
      <PortfolioItemCavoodles />,
      <PortfolioItemBloomCampervans />,
      <PortfolioItemCafePennoz />
    ]);
  }, []);

  return (
    <StyledPortfolioPage className="portfolio-page">
      <Desktop>
        <PortfolioPageDesktop />
      </Desktop>
      <Mobile>
        <PortfolioPageMobile />
      </Mobile>
      {/* <Link to="/">← Back to Page One</Link> */}
      {/* <ScrollandMousePLX /> */}
    </StyledPortfolioPage>
  );
}
