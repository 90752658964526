import React, { Fragment, useState, useRef, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useOnClickOutside } from "./BurgerMenu/useOnClickOutsideHook";
import { MainContext } from "../contexts/MainContext.js";
import Burger from "./BurgerMenu/Burger";
import Menu from "./BurgerMenu/Menu";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";

import { GlobalStyles } from "../global";
import { theme } from "../theme";
import CTAButton from "../buttons/CTAButton";

const StyledAppBar = styled(AppBar)`
  background-color: ${(props) =>
    props.location.pathname === "/"
      ? "transparent"
      : props.theme.backgroundLight};
  position: ${(props) =>
    props.location.pathname === "/" ? "fixed" : "relative"};
  box-shadow: none;
  transition: ${(props) => props.theme.backgroundTransition};
  & .MuiTabs-flexContainer {
    justify-content: space-between;
  }
  & .PrivateTabIndicator-colorSecondary-3 {
    background-color: #04fbc0;
    transition: 0.2s background-color ease-out;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .menu-tabs {
    color: ${(props) => props.theme.text};
    opacity: 1;
    font-size: 14px;
    font-weight: bold;
  }
  .home {
    border-bottom: ${(props) =>
      props.location.pathname === "/"
        ? "4px solid #04fbc0"
        : "4px solid transparent"};
    transition: 0.2s border ease-out;
  }
  .about {
    border-bottom: ${(props) =>
      props.location.pathname === "/about"
        ? "4px solid #04fbc0"
        : "4px solid transparent"};
    transition: 0.2s border ease-out;
  }
  .portfolio {
    border-bottom: ${(props) =>
      props.location.pathname === "/portfolio"
        ? "4px solid #04fbc0"
        : "4px solid transparent"};
    transition: 0.2s border ease-out;
  }
`;

const StyledMobileAppBar = styled(AppBar)`
  background-color: ${(props) =>
    props.location.pathname === "/"
      ? "transparent"
      : props.theme.backgroundLight};
  width: 100vw;
  position: fixed;
  box-shadow: none;
  overflow: visible;
  transition: ${(props) => props.theme.backgroundTransition};
  & .MuiTabs-flexContainer {
    justify-content: space-between;
  }
  & .PrivateTabIndicator-colorSecondary-66 {
    /* background-color: #2c2c28 !important; */
    transition: all 0.25s;
  }
  & .PrivateTabIndicator-colorSecondary-3 {
    color: white !important;
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

const StyledTab = styled(Tab)`
  background-color: none;
  color: ${(props) => props.theme.text};
  padding: 7px 14px;
  margin: 18px 24px;
  transition: all 0.25s ease-out;

  @media only screen and (max-width: 767px) {
    padding: 0px;
    margin: 18px 0 18px 24px;
  }
  .site-logo {
    margin: 0;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    color: ${(props) => props.theme.text};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    transition: color 0.25s ease-out;
  }
`;

export default function MobileTopNav(props) {
  const { isDesktop, topOrSideNav } = useContext(MainContext);
  let location = useLocation();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  const [value, setValue] = React.useState(0);
  // const [isDesktop, setDesktop] = useState(window.innerWidth > 767);

  // const updateMedia = () => {
  //   setDesktop(window.innerWidth > 767);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", updateMedia);
  //   return () => window.removeEventListener("resize", updateMedia);
  // });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Fragment>
        {/* Toggle between Desktop/Tablet Nav and Mobile Nav */}
        {isDesktop ? (
          <StyledAppBar position="static" location={location}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Top Navigation Bar"
            >
              <StyledTab
                className="site-logo-container menu-tabs home"
                icon={<h1 className="site-logo">Joshua Mamo.</h1>}
                component={Link}
                to="/"
              />
              <div>
                {topOrSideNav ? (
                  <Tab
                    label="Portfolio"
                    className="menu-tabs portfolio"
                    component={Link}
                    to="/portfolio"
                  />
                ) : null}
                {topOrSideNav ? (
                  <Tab
                    label="About"
                    className="menu-tabs about"
                    component={Link}
                    to="/about"
                  />
                ) : null}
                <CTAButton
                  margin="24px 36px"
                  text="Contact"
                  linkTo="/contact"
                />
              </div>
            </Tabs>
          </StyledAppBar>
        ) : (
          <StyledMobileAppBar position="static" location={location}>
            <Tabs
              className="navTabs"
              style={{ overflow: "visible" }}
              value={value}
              onChange={handleChange}
              aria-label="Top Navigation Bar"
            >
              <StyledTab
                icon={<h1 className="site-logo">Joshua Mamo.</h1>}
                component={Link}
                to="/"
              />
              <Burger open={open} setOpen={setOpen} aria-label="Mobile Menu" />
              <Menu open={open} setOpen={setOpen} />
            </Tabs>
          </StyledMobileAppBar>
        )}
      </Fragment>
    </ThemeProvider>
  );
}
