import React, { Fragment, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import CTAButton from "../../buttons/CTAButton";
import Box from "@material-ui/core/Box";
import SVG from "react-inlinesvg";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

import WhatIDoCardMobile from "./WhatIDoCardMobile";

const StyledMainBox = styled(Box)`
  position: absolute;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundLight};
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const TextContainerBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 20%;
  max-width: 450px;
  z-index: 100;
  /* Type Styles */
  @media screen and (max-width: 767px) {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 85px;
    left: 40px;
    height: auto;
    max-width: calc(100vw - 70px);
    z-index: 100;
    .MuiTypography-h1 {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin-bottom: 8px;
      /* white-space: nowrap; */
    }
    .MuiTypography-subtitle1 {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: ${(props) => props.theme.text};
      text-align: left;
      margin-bottom: 16px;
    }
  }
`;

const StyledButton = styled(Button)`
  background: #04fbc0;
  border-radius: 2px;
  box-shadow: none;
  padding: 12px 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #232323;
  align-self: flex-start;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const StyledCardsContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default function WhatIDoMobile() {
  const [activeWIDCard, setActiveWIDCard] = useState(null);
  return (
    <StyledMainBox>
      <TextContainerBox>
        <Typography className="h1-mobile" variant="h1" gutterBottom>
          What I do
          {/* {props.cardTitle} */}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Some text about all the awesome stuff I can do.
          {/* {props.subtitle} */}
        </Typography>
        <CTAButton
          // href="/portfolio"
          className="scale-in-hor-center"
          text="My Work"
          linkTo="/portfolio"
        />
      </TextContainerBox>
      <StyledCardsContainer>
        {/* <WhatIDoCardAccordion
        svgSrc="/svg/development.svg"
        cardTitle="Development"
        subtitle="We have packages suitable for all projects and budgets"
        body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        translateHor="0"
        translateVert="0"
        scale="80px"
      /> */}
        <WhatIDoCardMobile
          svgSrc="/svg/development.svg"
          cardTitle="Development"
          subtitle="We have packages suitable for all projects and budgets"
          body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt mollit anim id est laborum."
          translateHor="0"
          translateVert="0"
          scale="80px"
          index={1}
          activeIndex={activeWIDCard}
          onClick={() => {
            activeWIDCard === 1 ? setActiveWIDCard(null) : setActiveWIDCard(1);
          }}
        />
        <WhatIDoCardMobile
          svgSrc="/svg/development.svg"
          cardTitle="Performance Optimization"
          subtitle="We have packages suitable for all projects and budgets"
          body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 2occaecat cupidatat non proident, sunt in culpa qui officia 2deserunt mollit anim id est laborum."
          translateHor="0"
          translateVert="0"
          scale="80px"
          index={2}
          activeIndex={activeWIDCard}
          onClick={() => {
            activeWIDCard === 2 ? setActiveWIDCard(null) : setActiveWIDCard(2);
          }}
        />
        <WhatIDoCardMobile
          svgSrc="/svg/development.svg"
          cardTitle="UX Design and Review"
          subtitle="We have packages suitable for all projects and budgets"
          translateHor="0"
          translateVert="0"
          scale="80px"
          index={3}
          activeIndex={activeWIDCard}
          onClick={() => {
            activeWIDCard === 3 ? setActiveWIDCard(null) : setActiveWIDCard(3);
          }}
        />
        <WhatIDoCardMobile
          svgSrc="/svg/development.svg"
          cardTitle="UX Design and Review"
          subtitle="We have packages suitable for all projects and budgets"
          translateHor="0"
          translateVert="0"
          scale="80px"
          index={4}
          activeIndex={activeWIDCard}
          onClick={() => {
            activeWIDCard === 4 ? setActiveWIDCard(null) : setActiveWIDCard(4);
          }}
        />
        <WhatIDoCardMobile
          svgSrc="/svg/development.svg"
          cardTitle="UX Design and Review"
          subtitle="We have packages suitable for all projects and budgets"
          translateHor="0"
          translateVert="0"
          scale="80px"
          index={5}
          activeIndex={activeWIDCard}
          onClick={() => {
            activeWIDCard === 5 ? setActiveWIDCard(null) : setActiveWIDCard(5);
          }}
        />
        <WhatIDoCardMobile
          svgSrc="/svg/development.svg"
          cardTitle="UX Design and Review"
          subtitle="We have packages suitable for all projects and budgets"
          translateHor="0"
          translateVert="0"
          scale="80px"
          index={6}
          activeIndex={activeWIDCard}
          onClick={() => {
            activeWIDCard === 6 ? setActiveWIDCard(null) : setActiveWIDCard(6);
          }}
        />
      </StyledCardsContainer>
    </StyledMainBox>
  );
}
