/* In the interest of progressing quickly, I will focus on;
- Creating an impressive landing page to send people to 
- Displaying some pricing information
- Displaying what we do
- CTA - Contact
*/

import React, { useEffect, useState, useContext } from "react";
import { MainContext } from "./contexts/MainContext.js";
import "./styles.css";
import Controls from "./components/Controls";
import PortfolioPage from "./routes/portfolioPage/PortfolioPage";
import TopNav from "./components/TopNav";
import SideNav from "./components/SideNav";
import WhoPage from "./routes/whoPage/whoPage.js";
import WhatIDoComponent from "./routes/whatIDoPage/WhatIDoComponent";
import AboutPageComponent from "./routes/aboutPage/AboutPage";
import ContactPage from "./routes/contactPage/ContactPage";
import styled, { keyframes } from "styled-components";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Button from "@material-ui/core/Button";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { createBrowserHistory } from "history";
import { lightTheme, darkTheme } from "./theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./global";

import ReactGA from "react-ga";

const themeMUI = createMuiTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application!
    }
  }
});

const slideInLeft = keyframes`
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;
const slideOutLeft = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
`;

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutRight = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
`;

const PageContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  h2 {
    font-family: "M PLUS Rounded 1c", sans-serif;
    color: #2c2c28;
  }
  p {
    font-size: 1rem;
    max-width: 400px;
    margin: 20px auto;
    color: #2c2c28;
  }
  a {
    text-decoration: none;
    color: #fff;
    padding: 10px 20px;
    border: 1px solid #4776e6;
    border-radius: 5px;
    background: #2c2c28;
    border: none;
    display: block;
    max-width: 60%;
    margin: 0 auto 18px auto;
  }

  img {
    border-radius: 50%;
  }
`;

const PageElement = styled(Page)`
  &.page-enter {
    animation: ${slideInLeft} 0.2s forwards;
  }
  &.page-exit {
    animation: ${slideOutRight} 0.2s forwards;
  }
`;

function HomePage() {
  return (
    <PageElement>
      <WhoPage />
    </PageElement>
  );
}

function WhatIDoPage() {
  return (
    <PageElement>
      <WhatIDoComponent />
    </PageElement>
  );
}

function AboutPage() {
  return (
    <PageElement>
      <AboutPageComponent />
    </PageElement>
  );
}

function PortfolioPageFunct() {
  return (
    <PageElement>
      <PortfolioPage />
    </PageElement>
  );
}

function ContactPageFunct() {
  return (
    <PageElement>
      <ContactPage />
    </PageElement>
  );
}

export const appHistory = createBrowserHistory();

export default function App() {
  const { theme, setTheme, topOrSideNav } = useContext(MainContext);

  // // Initialize google analytics page view tracking
  // appHistory.listen((location) => {
  //   ReactGA.set({ page: location.pathname }); // Update the user's current page
  //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
  // });

  //Detect if the user prefers dark-mode on page load
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      // dark mode
      console.log("darkmode");
      setTheme("dark");
    }
  }, []);

  useEffect(() => {
    // This line will trigger on a route change
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router history={appHistory}>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <GlobalStyles />
        <MuiThemeProvider theme={themeMUI}>
          <TopNav />
          {topOrSideNav ? null : <SideNav />}
          <Controls />
        </MuiThemeProvider>
        <Route
          render={({ location }) => {
            return (
              <PageContainer>
                <TransitionGroup component={null}>
                  <CSSTransition
                    timeout={300}
                    classNames="page"
                    key={location.key}
                  >
                    <Switch location={location}>
                      <Route exact path="/" component={HomePage} />
                      {/* <Route exact path="/whatido" component={WhatIDoPage} /> */}
                      <Route exact path="/about" component={AboutPage} />
                      <Route
                        exact
                        path="/portfolio"
                        component={PortfolioPageFunct}
                      />
                      <Route
                        exact
                        path="/contact"
                        component={ContactPageFunct}
                      />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </PageContainer>
            );
          }}
        />
      </ThemeProvider>
    </Router>
  );
}
