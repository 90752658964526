import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import PortfolioCardDesktop from "../portfolioDesktop/PortfolioCardDesktop.js";
import PortfolioCardMobile from "../portfolioMobile/PortfolioCardMobile.js";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export default function PortfolioItemBrandedGW(props) {
  const portfolioItem = {
    cardTitle: "Branded Glassware",
    subtitle: "We have packages suitable for all projects and budgets",
    imgDesktop:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fbrandedglassware.jpg?alt=media&token=59362de7-9b66-46d0-8afb-721b76bda741",
    imgMobile:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fbrandedglassware-mobile.jpg?alt=media&token=e14ee92f-7180-443c-916c-0c2e9ed7a7ef",
    imgMobile2:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fbrandedglassware-mobile2.jpg?alt=media&token=f16a3a4a-abe9-4a80-8180-374acc67d966",
    imgMobile3:
      "https://firebasestorage.googleapis.com/v0/b/joshmamo-portfolio-site.appspot.com/o/images%2Fbrandedglassware-mobile3.jpg?alt=media&token=3d79378e-19fd-4085-88f0-7fe1e89e7156",
    tab1Title: "Brief",
    tab1Body: (
      <p>
        Create an ecommerce site for a new business to run alongside existing
        laser decoration business. <br /> Key considerations:
        <ul>
          <li>
            E-commerce site with a quantity discount pricing matrix for each
            product
          </li>
          <li>Hide wholesale pricing info unless user logged in</li>
          <li>User registration approval functionality</li>
        </ul>
      </p>
    ),
    tab2Title: "Design",
    tab2Body: (
      <p>
        A very simple and clean, modern design. With bold high contrast but
        muted colors this site targets an older audience and is thus purposely
        conservative and simple.
      </p>
    ),
    tab3Title: "Development",
    tab3Body: (
      <p>
        This site required quite a bit of custom functionality calling for
        custom PHP and JS. All modifications were applied through a chid theme
        and documented in a text document to aid in and future modifications or
        develoments by myself, the client or another dev.
        <br />
        Tech:
        <ul>
          <li>Wordpress and Woocommerce</li>
          <li>Twenty Twenty Theme</li>
          <li>Elementor</li>
        </ul>
      </p>
    ),
    tab4Title: "Performance",
    tab4Body:
      " Performance Graphics - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 1occaecat cupidatat non proident, sunt in culpa qui officia 1deserunt mollit anim id est laborum.",
    displayImageOnMobile: "mobile",
    alt: "Branded Glassware",
    url: "http://brandedglassware.com.au/"
  };

  return (
    <Fragment>
      <Desktop>
        <PortfolioCardDesktop
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          tab2Title={portfolioItem.tab2Title}
          tab2Body={portfolioItem.tab2Body}
          tab3Title={portfolioItem.tab3Title}
          tab3Body={portfolioItem.tab3Body}
          tab4Title={portfolioItem.tab4Title}
          tab4Body={portfolioItem.tab4Body}
          url={portfolioItem.url}
          alt={portfolioItem.alt}
        />
      </Desktop>
      <Mobile>
        <PortfolioCardMobile
          cardTitle={portfolioItem.cardTitle}
          subtitle={portfolioItem.subtitle}
          imgDesktop={portfolioItem.imgDesktop}
          imgMobile={portfolioItem.imgMobile}
          img2Mobile={portfolioItem.imgMobile2}
          img3Mobile={portfolioItem.imgMobile3}
          tab1Title={portfolioItem.tab1Title}
          tab1Body={portfolioItem.tab1Body}
          tab2Title={portfolioItem.tab2Title}
          tab2Body={portfolioItem.tab2Body}
          tab3Title={portfolioItem.tab3Title}
          tab3Body={portfolioItem.tab3Body}
          tab4Title={portfolioItem.tab4Title}
          tab4Body={portfolioItem.tab4Body}
          displayImageOnMobile="mobile"
          alt={portfolioItem.alt}
          index={props.index}
          activeIndex={props.activeIndex}
          url={portfolioItem.url}
          open={props.open}
        />
      </Mobile>
    </Fragment>
  );
}
