import React, { Fragment, useState } from "react";
import Box from "@material-ui/core/Box";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import ExpandButton from "../../../buttons/ExpandButton";
import PropTypes from "prop-types";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const CardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.open ? "100%" : "95%")}; // was 85% and 75%
  margin: 12px auto; // was 18px auto 0 7.5vw;
  padding: ${(props) => (props.open ? "18px 48px" : "18px calc(48px - 2.5vw)")};
  background-color: ${(props) => props.theme.cardBG};
  border-radius: 0 40px 0 40px;
  @media screen and (max-width: 480px) {
    padding: ${(props) => (props.open ? "24px 24px 6px" : "24px 24px 6px")};
  }
  /* Styles to lower the opacity of cards that are not currently selected */
  &.isSelectedCard {
    height: auto;
  }
  &.MuiBox-root {
    opacity: 1;
    transition: all 0.3s ease;
  }
  &.MuiBox-root.is-selected {
    opacity: 1;
  }
  /* Type Styles */
  .MuiTypography-h2 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: ${(props) => props.theme.text};
    text-align: left;
    /* white-space: nowrap; */
  }
  .MuiTypography-subtitle1 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
  }
`;

const ImageBox = styled(Box)`
  position: relative;
  overflow: hidden;
  margin: 6px auto 0;
  width: ${(props) => (props.scale ? props.scale : "auto")};
  height: ${(props) => (props.scale ? props.scale : "auto")};
  transform: ${(props) =>
    props.translateX && props.translateY
      ? `translate(${props.translateX}, ${props.translateY})`
      : "translate(0,0)"};

  /* -------- Image Styles -------- */
  .mobile-screenshots-container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .desktop-screenshot {
    position: relative;
    z-index: 3;
    width: 80vw;
    height: 45vw;
    margin: 0;
    object-fit: cover;
    object-position: 0px 0%;
    border-radius: 12px;
    border: 4px solid #232323;
    -webkit-transition: object-position 3s ease-in-out;
    -moz-transition: object-position 3s ease-in-out;
    -ms-transition: object-position 3s ease-in-out;
    -o-transition: object-position 3s ease-in-out;
    transition: object-position 3s ease-in-out;
  }
  .desktop-screenshot:hover {
    object-position: 0px 100%;
  }
  .mobile-screenshot {
    position: relative;
    z-index: 3;
    width: 23vw;
    height: 45vw;
    margin: 0 6px;
    object-fit: cover;
    object-position: 0px 0%;
    border-radius: 12px;
    border: 4px solid #232323;
    -webkit-transition: object-position 3s ease-in-out;
    -moz-transition: object-position 3s ease-in-out;
    -ms-transition: object-position 3s ease-in-out;
    -o-transition: object-position 3s ease-in-out;
    transition: object-position 3s ease-in-out;
  }
  .mobile-screenshot:hover {
    object-position: 0px 100%;
  }

  /* -------- Image Slideshow --------

  .image-slideshow {
    opacity: 0;
    animation: fadeinout 5s infinite;
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      z-index: 3;
    }
    50% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      z-index: 1;
    }
  } */
`;

const StyledExpandButton = styled(ExpandButton)``;

const BodyBox = styled(Box)`
  /* scrollbar styles */
  /* width */
::-webkit-scrollbar {
  width:0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
  overflow-y: scroll;
  max-height: ${(props) => (props.open ? "500px" : "0px")}; 
  /* transform-origin: 100% 0%;
  transform: ${(props) =>
    props.activeIndex === props.index ? "scaleY(1)" : "scaleY(0)"}; */
  transition:  0.8s max-height cubic-bezier(0.250, 0.460, 0.450, 0.940);
  width: 90%;
  margin: 0 auto;
  background-color: none;
    .MuiTypography-body1 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
    /* opacity: ${(props) => (props.activeIndex === props.index ? "1" : "0")}; ;
    transition: 0.5s opacity cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
    /* opacity: ${(props) => (props.activeIndex === props.index ? 1 : 0)}; */
  }
  p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.text};
    text-align: left;
    /* opacity: ${(props) => (props.activeIndex === props.index ? "1" : "0")}; ;
    transition: 0.5s opacity cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
    /* opacity: ${(props) => (props.activeIndex === props.index ? 1 : 0)}; */
  }

/* -------- Tab Styles ------------ */
  
  .portfolio-item-tabs {
  }

  .portfolio-item-tabs .MuiTabs-indicator {
    height: 46px;
    background-color: #04fbc0;
    border-radius: 2px;
  }

  .portfolio-item-tabs .MuiTab-root {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
    color: ${(props) => props.theme.text};
    font-size: 12px;
    font-weight: bold;
    z-index: 3;
    border-radius: 2px;
    transition: 0.25s all ease-out;
  }

  .portfolio-item-tabs .Mui-selected {
    color: #232323;
  }

  .portfolio-item-tabs .MuiTab-root:hover {
    position: relative;
    z-index: 3;
    border-radius: 2px;
  }

  .portfolio-item-tabs .MuiTab-root.Mui-selected {
    /* background-color: #04fbc0; */
  }

  .tab-panel {
    display: relative;
    z-index: 10;
  }
  
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.text};
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function PortfolioMobileCard(props) {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <CardBox className="card-bg" open={open}>
      <div>
        <Typography variant="h2" gutterBottom>
          {props.cardTitle}
        </Typography>
        {/* <Typography variant="subtitle1" gutterBottom>
          {props.subtitle}
        </Typography> */}
      </div>
      <ImageBox>
        {props.displayImageOnMobile !== "mobile" ? (
          <div className="image-slideshow desktop-screenshots-container">
            <img
              className="desktop-screenshot"
              translateX={props.translateHor}
              translateY={props.translateVert}
              src={props.imgDesktop}
              scale={props.scale}
              alt={props.alt}
            />
          </div>
        ) : (
          <div className="image-slideshow mobile-screenshots-container">
            <img
              className="mobile-screenshot"
              translateX={props.translateHor}
              translateY={props.translateVert}
              src={props.imgMobile}
              scale={props.scale}
              alt={props.alt}
            />
            <img
              className="mobile-screenshot"
              translateX={props.translateHor}
              translateY={props.translateVert}
              src={props.img2Mobile}
              scale={props.scale}
              alt={props.alt}
            />
            <img
              className="mobile-screenshot"
              translateX={props.translateHor}
              translateY={props.translateVert}
              src={props.img3Mobile}
              scale={props.scale}
              alt={props.alt}
            />
          </div>
        )}
      </ImageBox>
      <StyledExpandButton
        padding="6px"
        margin="12px"
        open={open}
        size="large"
        onClick={() => setOpen(!open)}
      />
      <BodyBox open={open}>
        <div class="portfolio-item-tabs">
          <Tabs
            className="portfolio-item-tabs"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {props.tab1Title ? (
              <Tab label={props.tab1Title} {...a11yProps(0)} />
            ) : null}
            {props.tab2Title ? (
              <Tab label={props.tab2Title} {...a11yProps(1)} />
            ) : null}{" "}
            {props.tab3Title ? (
              <Tab label={props.tab3Title} {...a11yProps(2)} />
            ) : null}{" "}
            {/* <Tab label={props.tab4Title} {...a11yProps(3)} /> */}
          </Tabs>
        </div>
        <TabPanel className="tab-panel" value={value} index={0}>
          <Typography variant="body1" gutterBottom>
            {props.tab1Body}
          </Typography>
        </TabPanel>
        <TabPanel className="tab-panel" value={value} index={1}>
          <Typography variant="body1" gutterBottom>
            {props.tab2Body}
          </Typography>
        </TabPanel>
        <TabPanel className="tab-panel" value={value} index={2}>
          <Typography variant="body1" gutterBottom>
            {props.tab3Body}
          </Typography>
        </TabPanel>
        <TabPanel className="tab-panel" value={value} index={3}>
          <Typography variant="body1" gutterBottom>
            {props.tab4Body}
          </Typography>
        </TabPanel>
      </BodyBox>
    </CardBox>
  );
}
